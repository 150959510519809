import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/cenovus/cenovus_first.jpg";
import Img2 from "./../assets/images/cenovus/cenovus_second.jpg";
import Img3 from "./../assets/images/cenovus/cenovus_third.jpg";
import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import RelatedProjects from "../components/RelatedProjects";

import Project_second from "./../assets/images/projectsImages/1.jpg"
import Project_first from "../assets/images/projectsImages/IMG_6229_edited.jpg";
import Project_third from "../assets/images/relatedprojects/pembina.jpg";
import Project_four from "../assets/images/relatedprojects/bow.jpg";

import { Link } from "react-router-dom";

const Cenovus = () => {

    const imageArray = [Img1, Img2, Img3];

    const relatedDates = [
        {
            title: "Burnet, Duckworth & Palmer LLP",
            url: "/portfolio_page/burnet-duckworth-palmer/",
            date: "September 24, 2012",
        },
        {
            title: "MEG Energy",
            url: "/portfolio_page/meg-energy/",
            date: "May 18, 2017",
        },
        {
            title: "Petrobakken Energy Ltd",
            url: "/portfolio_page/petrobakken/",
            date: "September 26, 2012",
        },
    ];

    const projects = [
        {
            title: "MEG Energy",
            firm: "Oil & Gas",
            image: Project_first
            , // Placeholder image URL
            link: "/portfolio_page/meg-energy/", // URL path or external link
        },
        {
            title: "Imperial Oil – Quarry Park",
            firm: "Oil & Gas",
            image: Project_second,
            link: "/portfolio_page/imperial-oil-quarry-park/",
        },
        {
            title: "Pembina Pipeline",
            firm: "Oil & Gas",
            image: Project_third,
            link: "/portfolio_page/cenovus-energy/",
        },
        {
            title: "Bow Tower",
            firm: "Oil & Gas",
            image: Project_four,
            link: "/portfolio_page/bow-tower/",
        },
    ];

    return (
        <>
            <div className="parent-div">
                <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Box>
                        <Contentimages
                            tab={"Calgary, Alberta"}
                            heading={"Cenovus Energy"}
                            imageArray={imageArray}
                        >
                            Designed by <Link to={"https://bharchitects.com/en/"} target="_blank" className="navigation-link">B+H Architects</Link>,The Cenovus Telephone Building was created with{" "}
                            <Link to={"http://www.cougar-contractors.com/"} target="_blank" className="navigation-link">Cougar Contractors</Link> to increase efficiency
                            and promote simplicity. Featuring effortless linear elements,
                            the MidWest Team executed various intricate design characteristics,
                            including <Link to={"https://koroseal.com/products/walltalkers"} target="_blank" className="navigation-link">Wall Talker</Link> wall coverings and a suspended ceiling with unique acoustical properties.
                        </Contentimages>
                        <Grid2 container paddingBottom={10}></Grid2>
                        <PostRealetedDates relatedDates={relatedDates} />
                        <ProtfolioNavigation
                            backUrl="/portfolio_page/pembina/"
                            nextUrl="/portfolio_page/bow-tower/"
                        />
                        <RelatedProjects
                            projects={projects}
                        />
                    </Box>
                </Container>
            </div>
        </>


    );
};

export default Cenovus;
