import React from "react";
import {
  Grid2,
  Typography,
  Box,
  CardMedia,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import "./../assets/css/Project.css";
import { useNavigate } from "react-router-dom";

const projectsList = [
  {
    title: "TransCanada Interior Renovation",
    image: require("./../assets/images/projectsImages/rsz_img_1707.jpg"),
    link: "/portfolio_page/transcanada-interior-renovation/",
  },
  {
    title: "Devon 3 Fitness Centre",
    image: require("./../assets/images/projectsImages/rsz_img_1525_edited.jpg"),
    link: "/portfolio_page/devon-3-fitness-centre/",
  },
  {
    title: "Ashley Furniture Homestore",
    image: require("./../assets/images/projectsImages/IMG_1195.jpg"),
    link: "/portfolio_page/ashley-furniture-homestore/",
  },
  {
    title: "Children’s Discovery Museum",
    image: require("./../assets/images/projectsImages/1-2.jpg"),
    link: "/portfolio_page/childrens-discovery-museum/",
  },
  {
    title: "Sage Hill Quarter",
    image: require("./../assets/images/projectsImages/Sage-Hill-Rendering.jpg"),
    link: "/portfolio_page/sage-hill-quarter/",
  },
  {
    title: "Harry Hays – Parks Canada",
    image: require("./../assets/images/projectsImages/IMG_0861_edited-1.jpg"),
    link: "/portfolio_page/harry-hays-parks-canada-2/",
  },
  {
    title: "Sheraton Eau Claire",
    image: require("./../assets/images/projectsImages/IMG_9262-1.jpg"),
    link: "/portfolio_page/sheraton-eau-claire/",
  },
  {
    title: "Alberta Utilities Commission",
    image: require("./../assets/images/projectsImages/IMG_9190-1.jpg"),
    link: "/portfolio_page/alberta-utilities-commission/",
  },
  {
    title: "Esker Art Gallery",
    image: require("./../assets/images/projectsImages/Esker-BA-LI.jpg"),
    link: "/portfolio_page/esker-art-gallery-2/",
  },
  {
    title: "CertainTeed",
    image: require("./../assets/images/projectsImages/IMG_8239.jpg"),
    link: "/portfolio_page/certainteed/",
  },
  {
    title: "MEG Energy",
    image: require("./../assets/images/projectsImages/IMG_6229_edited.jpg"),
    link: "/portfolio_page/meg-energy/",
  },
  {
    title: "Cisco Systems",
    image: require("./../assets/images/projectsImages/IMG_4726_edited.jpg"),
    link: "/portfolio_page/cisco/",
  },
  {
    title: "Field Law Calgary",
    image: require("./../assets/images/projectsImages/IMG_2218_edited.jpg"),
    link: "/portfolio_page/field-law/",
  },
  {
    title: "Simons",
    image: require("./../assets/images/projectsImages/0052-MIDWEST-SIMMONS.jpg"),
    link: "/portfolio_page/simons/",
  },
  {
    title: "National Music Centre",
    image: require("./../assets/images/projectsImages/0012-NMCApril2016.jpg"),
    link: "/portfolio_page/national-music-centre/",
  },
  {
    title: "Gulf Canada Square Food Court",
    image: require("./../assets/images/projectsImages/0038_-MIDWEST-GULFCANADA.jpg"),
    link: "/portfolio_page/gulf-canada-square/",
  },
  {
    title: "Nordstrom Chinook",
    image: require("./../assets/images/projectsImages/0031-MidwestNordstrom.jpg"),
    link: "/portfolio_page/nordstrom/",
  },
  {
    title: "Imperial Oil – Quarry Park",
    image: require("./../assets/images/projectsImages/1.jpg"),
    link: "/portfolio_page/imperial-oil-quarry-park/",
  },
  {
    title: "Walden Heights",
    image: require("./../assets/images/projectsImages/IMG_2592.jpg"),
    link: "/portfolio_page/walden-heights/",
  },
  {
    title: "Gowling WLG",
    image: require("./../assets/images/projectsImages/0098-MidwestLocation-1.jpg"),
    link: "/portfolio_page/gowling/",
  },
  {
    title: "Pembina Pipeline",
    image: require("./../assets/images/projectsImages/0072-MIDWEST-8thAVEPLACE_edited.jpg"),
    link: "/portfolio_page/pembina/",
  },
  {
    title: "Cenovus Energy",
    image: require("./../assets/images/projectsImages/0001-MidwestLocation_edited.jpg"),
    link: "/portfolio_page/cenovus-energy/",
  },
  {
    title: "Bow Tower",
    image: require("./../assets/images/projectsImages/EnCana_edited.jpg"),
    link: "/portfolio_page/bow-tower/",
  },
  {
    title: "Nexen Wellness",
    image: require("./../assets/images/projectsImages/Nexen-Wellness-37.jpg"),
    link: "/portfolio_page/nexen-wellness/",
  },
  {
    title: "Arcan Resources",
    image: require("./../assets/images/projectsImages/IMG_8024.jpg"),
    link: "/portfolio_page/arcan-resources/",
  },
  {
    title: "Torys LLP",
    image: require("./../assets/images/projectsImages/MG_5075-900-x-600.jpg"),
    link: "/portfolio_page/torys/",
  },
  {
    title: "Petrobakken Energy Ltd",
    image: require("./../assets/images/projectsImages/IMG_7945-900-x-675.jpg"),
    link: "/portfolio_page/petrobakken/",
  },
  {
    title: "Heenan Blaikie LLP",
    image: require("./../assets/images/projectsImages/IMG_7971-675-x-900.jpg"),
    link: "/portfolio_page/heenan-blaikie/",
  },
  {
    title: "Burnet, Duckworth & Palmer LLP",
    image: require("./../assets/images/projectsImages/IMG_7909-900-x-675.jpg"),
    link: "/portfolio_page/burnet-duckworth-palmer/",
  },
];

const ProjectHome = () => {

  let navigate = useNavigate();


  const onProjectClick = (link) => {
    if (!link) return null;
    navigate(link);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <>
      <Box sx={{ paddingTop: 5 }}>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h2"
            component="h2"
            style={{ fontWeight: "600" }}
            gutterBottom
            className="Main-content"
          >
            Notable Projects
          </Typography>
        </Box>

        <Grid2 container spacing={4} marginTop={5} className="project-list">
          {projectsList.map((project, index) => (
            <Grid2 item size={{ xs: 12, sm: 6, md: 4 }} key={index}>
              <Box
                href={project.link}
                onClick={() => {
                  onProjectClick(project?.link ?? null);
                }}
                sx={{ textDecoration: "none", cursor: "pointer" }}
              >
                <Box className="project-card">
                  <Box className="card-overlay">
                    <Tooltip
                      title={project.title}
                      slotProps={{
                        popper: {
                          sx: {
                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                              {
                                marginTop: "0px",
                              },
                          },
                        },
                      }}
                    >
                      <span className="add-icon">+</span>
                    </Tooltip>
                  </Box>
                  <CardMedia
                    component="img"
                    height="300"
                    image={project.image}
                    alt={project.title}
                    sx={{
                      objectFit: "cover",
                      width: "100%",
                      height: "100%",
                    }}
                  />
                </Box>

                <Typography
                  gutterBottom
                  variant="h6"
                  component="div"
                  className="sub-title"
                  sx={{ textAlign: "center", fontWeight: 550 }}
                >
                  {project.title}
                </Typography>
              </Box>
            </Grid2>
          ))}
        </Grid2>
      </Box>

      {/* {showScroll && (
        <IconButton
          sx={{
            position: "fixed",
            bottom: 40,
            right: 40,
            padding: "10px",
            backgroundColor: "rgba(95,140,39,1)",
            "&:hover": {
              backgroundColor: "rgba(95,140,39,1)",
              border: "1px solid #fff",
            },
          }}
          onClick={scrollToTop}>
          <ArrowUpwardIcon style={{ color: "#fff" }} />
        </IconButton>
      )} */}
    </>
  );
};

export default ProjectHome;