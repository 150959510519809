import { Box, Container, Stack } from "@mui/material";
import React from "react";
import "../assets/css/Contact.css";
import Grid from "@mui/material/Grid2";

export default function Contact() {
  return (
    <Container className="contact-container">
      <Box className="contact-box">
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
            <Stack alignItems={"start"}>
              <h3 className="contact-info">
                <strong>
                  <span style={{ color: "#5f8c27" }}>Contact</span>
                </strong>
              </h3>
              <div className="seprator"></div>
              <p style={{ marginBottom: "0px", marginTop: "5px" }}>
                <span className="information">
                  <strong>info@midwest1988.com</strong>
                </span>
              </p>
              <p style={{ marginBottom: "0px", marginTop: "5px" }}>
                <span className="information">
                  <strong>403-277-1551</strong>
                </span>
              </p>
              {/* <p style={{ marginBottom: "0px", marginTop: "5px" }}>
                <span className="information">
                  <strong>SK: 306-500-0185</strong>
                </span>
              </p> */}
            </Stack>
          </Grid>
          <Grid size={{ xs: 12, sm: 12, md: 12, lg: 6, xl: 6 }}>
            <Stack alignItems={"start"}>
              <h3 className="contact-info">
                <strong>
                  <span style={{ color: "#5f8c27" }}>Head Office</span>
                </strong>
              </h3>
              <div className="seprator"></div>
              <p
                style={{ marginBottom: "0px", marginTop: "5px" }}
                className="no-wrap"
              >
                <span className="information">
                  <strong>261185 Wagon Wheel Way</strong>
                </span>
              </p>
              <p style={{ marginBottom: "0px", marginTop: "5px" }}>
                <span className="information">
                  <strong>Rocky View, AB T4A 0E2</strong>
                </span>
              </p>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}