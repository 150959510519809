import { Box, Container } from "@mui/material";
import React from "react";
import Contentimages from "../components/Contentimages";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import ProjectImg1 from "./../assets/images/relatedprojects/ashley_furniture.jpg";
import ProjectImg2 from "./../assets/images/relatedprojects/simons.jpg";
import ProjectImg3 from "./../assets/images/relatedprojects/gulf_canada.jpg";
import ProjectImg4 from "./../assets/images/relatedprojects/nordstrom.jpg";
import RelatedProjects from "../components/RelatedProjects";

const SageHillQuarter = () => {

    const projects = [
        {
            title: "Ashley Furniture HomeStore",
            firm: "Retail",
            image: ProjectImg1,
            link: "/portfolio_page/ashley-furniture-homestore/",
        },
        {
            title: "Simons",
            firm: "Retail",
            image: ProjectImg2,
            link: "/portfolio_page/simons/",
        },
        {
            title: "Gulf Canada Square Food Court",
            firm: "Retail",
            image: ProjectImg3,
            link: "/portfolio_page/gulf-canada-square/",
        },
        {
            title: "Nordstrom Chinook",
            firm: "Retail",
            image: ProjectImg4,
            link: "/portfolio_page/nordstrom/",
        },
    ];

    const onUrlClick = (url) => {
        window.open(url, '_blank');
    }

    return (
        <>
            <div className="parent-div">
                <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Box>
                        <Contentimages
                            heading={"Sage Hill Quarter"}
                            tab={"Calgary, Alberta"}
                            imageArray={[]}
                        >


                            MidWest Group is working hard to construct the steel stud and drywall assemblies of eleven buildings at Northwest Calgary’s
                            prestigious new retail centre, Sage Hill Quarter. Under contract to
                            <span className="portfolio_url_text"
                                onClick={() => {
                                    onUrlClick("http://www.canaconstruction.com/projects/sage-hill-quarter/")
                                }}
                            > CANA Construction</span>
                            ,
                            our scope includes interior and exterior walls, fire-rated shafts, soffits, build-outs, and parapets.

                            <br />
                            <br />

                            Designed by

                            <span className="portfolio_url_text"
                                onClick={() => {
                                    onUrlClick("https://zeidler.com/")
                                }}
                            > Zeidler BKDI </span>
                            and comprised of an array of shops and services, the finished development will create a
                            convenient shopping experience benefitting the surrounding communities.

                            <br />
                            <br />
                            The retail complex is scheduled for completion in 2019.

                        </Contentimages>
                        <ProtfolioNavigation
                            backUrl="/portfolio_page/childrens-discovery-museum/"
                            nextUrl="/portfolio_page/harry-hays-parks-canada-2/"
                        />

                        <RelatedProjects
                            projects={projects}
                        />
                    </Box>
                </Container>
            </div>
        </>
    )
}

export default SageHillQuarter