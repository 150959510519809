import Grid from "@mui/material/Grid2";
import React, { useState } from "react";
import "./../assets/css/Contentimage.css";
import { Icon } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ImagePreview from './ImagePreview';

export default function Contentimages(props) {
    

    const [openImagePreview, setOpenImagePreview] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(false);

    return (
        <>
            <Grid container>
                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 8, xl: 8 }}>
                    <div className="main-left-div">
                        <h1 className="heading-info">{props?.heading ?? ""}</h1>
                        <div className="sub-heading-div">
                            <h5 className="sub-heading-info">{props?.tab ?? ""}</h5>
                            <div className="separator"></div>
                        </div>
                        <p className="description">{props?.children ?? ""}</p>
                    </div>
                </Grid>

                <Grid size={{ xs: 12, sm: 12, md: 12, lg: 4, xl: 4 }}>
                    <Grid container spacing={1} rowSpacing={0.1} className="image-list">
                        {props?.imageArray?.map((item, index) => (
                            <Grid size={{ xs: 12, sm: 12, md: 12, lg: 3, xl: 3 }} className={"gallary-holder"} position={"relative"}
                                onClick={() => {
                                    setCurrentIndex(index)
                                    setOpenImagePreview(true);
                                }}
                            >
                                <img src={item} alt="" className="content-img" />
                                {openImagePreview ? (
                                    <></>
                                ) : (
                                    <Icon fontSize="large" className="image-icon">
                                        <SearchIcon sx={{ fontSize: "3.5rem" }} fontSize="large" />
                                    </Icon>
                                )}
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

            </Grid>

            {
                openImagePreview && <ImagePreview
                    open={openImagePreview}
                    handleClose={() => {
                        setOpenImagePreview(false);
                    }}
                    images={props?.imageArray ?? []}
                    currentIndex={currentIndex}
                />

            }

        </>
    );
}
