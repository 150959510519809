import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/nordstrom/nordstrome_first.jpg";
import Img2 from "./../assets/images/nordstrom/nordstrome_second.jpg";
import Img3 from "./../assets/images/nordstrom/nordstrome_third.jpg";
import Img4 from "./../assets/images/nordstrom/nordstrome_four.jpg";
import Img5 from "./../assets/images/nordstrom/nordstrome_five.jpg";
import Img6 from "./../assets/images/nordstrom/nordstrome_six.jpg";
import Img7 from "./../assets/images/nordstrom/nordstrome_seven.jpg";
import Img8 from "./../assets/images/nordstrom/nordstrome_eight.jpg";
import Img9 from "./../assets/images/nordstrom/nordstrome_nine.jpg";

import Project_first from "./../assets/images/relatedprojects/ashley_furniture.jpg";
import Project_second from "./../assets/images/relatedprojects/sage_hill.png";
import Project_third from "./../assets/images/relatedprojects/simons.jpg";
import Project_four from "./../assets/images/relatedprojects/gulf_canada.jpg";


import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import RelatedProjects from "../components/RelatedProjects";


const Nordstrom = () => {
  const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9];

  const relatedDates = [
    {
      title: "Burnet, Duckworth & Palmer LLP",
      url: "/portfolio_page/burnet-duckworth-palmer/",
      date: "September 24, 2012",
    },
    {
      title: "National Music Centre",
      url: "/portfolio_page/national-music-centre/",
      date: "January 21, 2017",
    },
    {
      title: "Devon 3 Fitness Centre",
      url: "/portfolio_page/devon-3-fitness-centre/",
      date: "February 26, 2019",
    },
  ];

  const projects = [
    {
      title: "Ashley Furniture HomeStore",
      firm: "Retail",
      image: Project_first,
      link: "/portfolio_page/ashley-furniture-homestore/",
    },
    {
      title: "Sage Hill Quarter",
      firm: "Retail",
      image: Project_second,
      link: "/portfolio_page/sage-hill-quarter/",
    },
    {
      title: "Simons",
      firm: "Retail",
      image: Project_third,
      link: "/portfolio_page/simons/",
    },
    {
      title: "Gulf Canada Square Food Court",
      firm: "Retail",
      image: Project_four,
      link: "/portfolio_page/gulf-canada-square/",
    },
  ];

  const onUrlClick = (url) => {
    window.open(url, '_blank');
  }

  return (
    <>
      <div className="parent-div">
        <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Box>
            <Contentimages
              tab={"Calgary, Alberta"}
              heading={"Nordstrom"}
              imageArray={imageArray}>
              MidWest Contracting is pleased to be part of the completion of
              Nordstrom in Chinook Centre. Our team provided finished ceilings,
              wall steel stud and a unistrut package to the 137.259 square feet
              space. A few of our accomplishments include installing an
              <span className="portfolio_url_text"
                onClick={() => {
                  onUrlClick("http://fryreglet.com/")
                }}
              > Aluminum Fry Reglet </span>
              lighting reveal,
              <span className="portfolio_url_text"
                onClick={() => {
                  onUrlClick("https://www.formglas.com/")
                }}
              > Formglas Columns </span>
              (glass fiber reinforced gypsum) and cloud ceilings with a level 5
              finish. These cloud ceilings were hung with pencil rod and we
              supplied 1-5/8” Metal unistrut for display units; which was
              standard throughout the entire store. One of the most noticeable
              pieces of the project is the
              <span className="portfolio_url_text"
                onClick={() => {
                  onUrlClick("https://www.9wood.com/")
                }}
              > 9Wood Ceilings </span>
              that we installed with a T-bar grid suspension above and with
              eucalyptus plain sliced veneer.
            </Contentimages>

            <Grid2 container paddingBottom={10}></Grid2>
            <PostRealetedDates relatedDates={relatedDates} />
            <ProtfolioNavigation
              backUrl="/portfolio_page/gulf-canada-square/"
              nextUrl="/portfolio_page/imperial-oil-quarry-park/"
            />
            <RelatedProjects projects={projects} />
          </Box>
        </Container>
        
      </div>
    </>
  );
};

export default Nordstrom;
