import { Box, Container } from "@mui/material";
import React from "react";
import Contentimages from "../components/Contentimages";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import Img1 from "./../assets/images/ChildernDiscovery/1.jpg";
import ProjectImg1 from "./../assets/images/relatedprojects/national_music.jpg";
import RelatedProjects from "../components/RelatedProjects";

const ChildernDiscovery = () => {
    const imageArray = [Img1];

    const onUrlClick = (url) => {
        window.open(url, '_blank');
    }


    const projects = [
        {
            title: "National Music Centre",
            firm: "Tourism",
            image: ProjectImg1,
            link: "/portfolio_page/national-music-centre/",
        },
    ];

    return (
        <>
            <div className="parent-div">
                <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Box>
                        <Contentimages
                            heading={"Children’s Discovery Museum"}
                            tab={"Saskatoon, Saskatchewan"}
                            imageArray={imageArray}
                        >

                            MidWest Group is pleased to be working alongside construction management company
                            <span className="portfolio_url_text"
                                onClick={() => {
                                    onUrlClick("https://stratadevelopment.ca/")
                                }}
                            > Strata Development </span>
                            to construct the interior walls and ceilings of Saskatoon’s new

                            <span className="portfolio_url_text"
                                onClick={() => {
                                    onUrlClick("http://www.museumforkids.sk.ca/future_home/")
                                }}
                            > Children’s Discovery Museum on the Saskatchewan</span>.

                            <br />
                            <br />

                            Located in the Mendel building, consultant

                            <span className="portfolio_url_text"
                                onClick={() => {
                                    onUrlClick("https://www.kindrachuk-agrey.ca/#!/projects/childrens-discovery")
                                }}
                            > Kindrachuk Agrey </span>
                            has incorporated
                            the innovative design into the heritage building, while

                            <span className="portfolio_url_text"
                                onClick={() => {
                                    onUrlClick("https://argyledesign.com/project/childrens-discovery-museum-on-the-saskatchewan/")
                                }}
                            > Argyle Design </span>
                            has
                            created exhibits that will inspire a new generation of children to explore
                            and express their creativity.

                            <br />
                            <br />

                            A museum created with the intent to stimulate young minds must be eye-catching,
                            but also requires enhanced acoustic control. The use of special products and unique
                            designs achieve both goals, including:
                            <ul style={{ margin: '0px', padding: '0px', marginLeft: '15px' }}>
                                <li>Armstrong Calla Tile</li>
                                <li>Armstrong Soundscapes</li>
                                <li>Armstrong Tectum Panels</li>
                                <li>Claro® Decoustics Panels</li>
                                <li>Curved partitions and bulkheads</li>
                                <li>Decoustics Wall Panels</li>
                                <li>Xorel Flat Hex Panels</li>
                            </ul>

                            <br />
                            Set to open early 2019, we are excited to see the finished museum!
                        </Contentimages>
                        <ProtfolioNavigation
                            backUrl="/portfolio_page/ashley-furniture-homestore/"
                            nextUrl="/portfolio_page/sage-hill-quarter/"
                        />

                        <RelatedProjects
                            projects={projects}
                        />
                    </Box>
                </Container>
            </div>
        </>
    )
}

export default ChildernDiscovery