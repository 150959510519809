import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import logo from "../assets/images/midwestlogo.png";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const navItems = [
  {
    path: "/",
    title: "Home",
  },
  {
    path: "/scope/",
    title: "What we do",
  },
  {
    path: "/safety/",
    title: "Safety",
  },
  {
    path: "/projects/",
    title: "Projects",
  },
];

function DrawerAppBar(props) {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("location---", location.pathname);
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [selectOpen, setSelectOpen] = React.useState(false); // State for select visibility

  const handleClickContact = () => {
    navigate("/contact/");
    setMobileOpen(false);
  };
  const handleClickCareers = () => {
    navigate("/careers/");
    setMobileOpen(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
    setSelectOpen(false);
  };

  const handleRoute = (path) => {
    navigate(path);
    setMobileOpen(false);
  };

  const handleSelectToggle = () => {
    setSelectOpen((prevState) => !prevState);
    setMobileOpen(true);
  };

  const drawer = (
    <Box sx={{ textAlign: "center" }}>
      <div style={{ display: "flex", padding: "0px 16px" }}>
        <IconButton
          className="menuIcon"
          size="large"
          color="inherit"
          aria-label="open drawer"
          edge="start"
          // onClick={handleDrawerClose}
          onClick={handleDrawerToggle}
          sx={{ display: { sm: "none", ml: 3 } }}
        >
          <MenuIcon fontSize="large" />
        </IconButton>
        <div className="drawer-header-div">
          <Link to="/" className="drawer-header-link">
            <img className="drawer-header-logo" src={logo} alt="" />
          </Link>
        </div>
      </div>
      <List disablePadding sx={{ width: "100%", padding: "0px 20px" }}>
        {navItems.map((item) => (
          <>
            <ListItem key={item} disablePadding>
              <ListItemButton
                sx={{ textAlign: "start" }}
                // onClick={() => handleRoute(item.path)}
                onClick={() => handleRoute(item.path)}
              >
                <NavLink
                  style={{ paddingLeft: "0px", padding: "5px 0px" }}
                  className="moblie_nav_item"
                  to={item.path}
                  end
                >
                  {item?.title}
                </NavLink>
              </ListItemButton>
            </ListItem>
          </>
        ))}
        <div>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ textAlign: "start" }}
              onClick={handleSelectToggle}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div>
                  <NavLink
                    style={{ paddingLeft: "0px", padding: "5px 0px" }}
                    className={
                      location.pathname ==="/contact/" ||
                      location.pathname === "/careers/"
                        ? "moblie_nav_item"
                        : "moblie_nav_item-inactive"
                    }
                    end
                  >
                    Get in Touch
                  </NavLink>
                </div>
                <div>
                  {!selectOpen ? (
                    <ChevronRightIcon
                      sx={{ color: "GrayText" }}
                      fontSize="small"
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      sx={{ color: "GrayText" }}
                      fontSize="small"
                    />
                  )}
                </div>
              </div>
            </ListItemButton>
          </ListItem>
          {selectOpen && (
            <>
              <ListItem disablePadding>
                <ListItemButton
                  sx={{ textAlign: "start" }}
                  onClick={() => handleClickContact()}
                >
                  <NavLink
                    style={{ paddingLeft: "0px", padding: "5px 0px" }}
                    className="moblie_nav_item"
                    to={"/contact/"}
                    end
                  >
                    Contact
                  </NavLink>
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding>
                <ListItemButton
                  sx={{ textAlign: "start" }}
                  onClick={() => handleClickCareers()}
                >
                  <NavLink
                    style={{ paddingLeft: "0px", padding: "5px 0px" }}
                    className="moblie_nav_item"
                    to={"/careers/"}
                    end
                  >
                    Careers
                  </NavLink>
                </ListItemButton>
              </ListItem>
            </>
          )}
        </div>
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav" className="topbar-main">
        <Toolbar className="toolbar-height">
          <IconButton
            className="menuIcon"
            size="large"
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none", ml: 3 } }}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
          <div className="drawer-header-div">
            <Link to="/" className="drawer-header-link">
              <img className="drawer-header-logo" src={logo} alt="" />
            </Link>
          </div>
          <Box
            sx={{
              display: { xs: "none", sm: "flex", alignItems: "center" },
              paddingLeft: { xs: "0px", sm: "14px" },
            }}
          >
            {navItems.map((item) => (
              <NavLink className="main-menu nav_item" to={item.path} end>
                {item?.title}
              </NavLink>
            ))}

            <div className="dropdown">
              <NavLink className="main-menu nav_item1" end>
                Get In Touch
              </NavLink>
              <div class="dropdown-content">
                <NavLink className="main-menu " to={"/contact/"} end>
                  Contact
                </NavLink>
                <NavLink className="main-menu " to={"/careers/"} end>
                  Careers
                </NavLink>
              </div>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
      <nav style={{ position: "fixed", backgroundColor: "white" }}>
        <Drawer
          key={"top"}
          anchor={"top"}
          container={container}
          variant="persistent"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ flexGrow: 1, p: 0 }}>
        <Toolbar className="toolbar-height" />
        {props.children}
      </Box>
    </Box>
  );
}

export default DrawerAppBar;