import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Contentimages from "../components/Contentimages";
import ProtfolioNavigation from "../components/ProtfolioNavigation";

import Img1 from "./../assets/images/ArcanResources/1.jpg";
import Img2 from "./../assets/images/ArcanResources/2.jpg";
import Img3 from "./../assets/images/ArcanResources/3.jpg";
import Img4 from "./../assets/images/ArcanResources/4.jpg";
import Img5 from "./../assets/images/ArcanResources/5.jpg";
import Img6 from "./../assets/images/ArcanResources/6.jpg";
import Img7 from "./../assets/images/ArcanResources/7.jpg";
import Img8 from "./../assets/images/ArcanResources/8.jpg";

import ProjectImg1 from "./../assets/images/projectsImages/IMG_6229_edited.jpg";
import ProjectImg2 from "./../assets/images/projectsImages/1.jpg";
import ProjectImg3 from "./../assets/images/relatedprojects/pembina.jpg";
import ProjectImg4 from "./../assets/images/relatedprojects/cenovus.jpg";

import RelatedProjects from "../components/RelatedProjects";


const ArcanResources = () => {
    const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8];

    const projects = [
        {
            title: "MEG Energy",
            firm: "Oil & Gas",
            image: ProjectImg1,
            link: "/portfolio_page/meg-energy/",
        },
        {
            title: "Imperial Oil – Quarry Park",
            firm: "Oil & Gas",
            image: ProjectImg2,
            link: "/portfolio_page/imperial-oil-quarry-park/",
        },
        {
            title: "Pembina Pipeline",
            firm: "Oil & Gas",
            image: ProjectImg3,
            link: "/portfolio_page/pembina/",
        },
        {
            title: "Cenovus Energy",
            firm: "Oil & Gas",
            image: ProjectImg4,
            link: "/portfolio_page/cenovus-energy/",
        }
    ];


    return (
        <>
            <div className="parent-div">
                <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Box>
                        <Contentimages
                            heading={"Arcan Resources"}
                            tab={"Calgary, Alberta"}
                            imageArray={imageArray}
                        >
                        </Contentimages>
                        <Grid2 container paddingBottom={5}></Grid2>
                        <ProtfolioNavigation
                            backUrl="/portfolio_page/nexen-wellness/"
                            nextUrl="/portfolio_page/torys/"
                        />

                        <RelatedProjects
                            projects={projects}
                        />

                    </Box>
                </Container>
            </div>
        </>
    )
}


export default ArcanResources