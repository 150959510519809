import React from 'react'
import { Grid2 } from "@mui/material";
import "./../assets/css/PostRealetedDates.css"
import { useNavigate } from 'react-router-dom';

const PostRealetedDates = (props) => {

    let navigate = useNavigate();

    return (
        <>
            <div className='post_realted_text_container'>
                <span className="post-related-text">
                    <b>Related</b>
                </span>
            </div>
            <Grid2 container sx={{ paddingTop: "10px" }}>
                {
                    props?.relatedDates?.map((item, index) => (
                        <Grid2 size={{ xs: 6, md: 4 }} key={index}>
                            <div>
                                <span className='related_title' onClick={() => {
                                    if (item?.url) {
                                        navigate(item?.url);
                                        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                                    }
                                }}>{item?.title}</span>
                            </div>
                            <p className='related_date'>{item?.date}</p>
                            <p className='related_date'>Similar post</p>
                        </Grid2>
                    ))
                }
            </Grid2>
        </>
    )
}

export default PostRealetedDates