import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/walden/waldon_first.jpg";
import Img2 from "./../assets/images/walden/waldon_second.jpg";
import Contentimages from "../components/Contentimages";
import ProtfolioNavigation from "../components/ProtfolioNavigation";


const Waldenheights = () => {

  const imageArray = [Img1, Img2];

  return (
    <>
      <div className="parent-div">
        <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Box>
            <Contentimages
              tab={"Calgary, Alberta"}
              heading={"Walden Heights"}
              imageArray={imageArray}
            >
            </Contentimages>
            <Grid2 container paddingBottom={10}></Grid2>
            <ProtfolioNavigation
              backUrl="/portfolio_page/imperial-oil-quarry-park/"
              nextUrl="/portfolio_page/gowling/"
            />
          </Box>
        </Container>
      </div>
    </>


  );
};

export default Waldenheights;
