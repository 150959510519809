import { Box, Container, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export default function Career() {
    return(
        <>
        <Container
        sx={{
            minHeight: "55vh",
            display: "flex",
            flexDirection: "column",
          }}
          >
        <Box sx={{flex:"1", paddingTop: 5}}>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h2"
            component="h2"
            style={{ fontWeight: "600" }}
            gutterBottom
            className="Main-content">
           Careers
          </Typography>
        </Box>
        <p style={{fontSize:"18px"}} className="aboutparagraph">
        Join our team! 
        </p>
        <p style={{textAlign:"center"}}>
        As a fast-growing company, we are searching for candidates who align with our core values. We offer a dynamic, fast-paced work environment where driven, self-motivated individuals can thrive. Joining our team means committing to providing the highest level of professional building services and innovative construction technology solutions.  
        </p>
        <p style={{textAlign:"center"}}>To learn more please visit: <Link style={{textDecoration:"none", color:"#5f8c27"}} to="https://www.thermalsystems.ca/join-our-team/" target="_Blank">https://www.thermalsystems.ca/join-our-team/</Link></p>

        </Box>
        </Container>
        </>
    )
}