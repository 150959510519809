import React, { useEffect, useState } from 'react';
import './../assets/css/ImageCarousel.css';
import image1 from './../assets/images/scope/scope_image_1.jpg'
import image3 from './../assets/images/scope/scope_image_3.jpg'
import image4 from './../assets/images/scope/scope_image_4.jpg'
import image5 from './../assets/images/scope/scope_image_5.jpg'
import image6 from './../assets/images/scope/scope_image_6.jpg'
import image7 from './../assets/images/scope/scope_image_7.jpg'
import image8 from './../assets/images/scope/scope_image_8.jpg'
import image9 from './../assets/images/scope/scope_image_9.jpg'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

const images = [
    image1,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
];

const ImageCarousel = () => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const [fade, setFade] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setFade(true); // Start fade out
            setTimeout(() => {
                setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
                setFade(false); // Start fade in
            }, 500); // Match with CSS transition duration
        }, 5000); // Change image every 5 seconds

        return () => clearInterval(interval);
    }, []);

    const handleCircleClick = (index) => {
        setFade(true); // Start fade out
        setTimeout(() => {
            setCurrentIndex(index);
            setFade(false); // Start fade in
        }, 500); // Match with CSS transition duration
    };

    const handleNext = () => {
        setFade(true);
        setTimeout(() => {
            setCurrentIndex((currentIndex + 1) % images.length);
            setFade(false);
        }, 500);
    };

    const handlePrev = () => {
        setFade(true);
        setTimeout(() => {
            setCurrentIndex((currentIndex - 1 + images.length) % images.length);
            setFade(false);
        }, 500);
    };

    return (
        <div className="carousel-container">
            <div className="image-wrapper">
                <img
                    src={images[currentIndex]}
                    alt=""
                    className={`carousel-image ${fade ? 'fade' : ''}`}
                />
                <KeyboardArrowLeftIcon className="arrow left" onClick={handlePrev}>
                </KeyboardArrowLeftIcon>
                {/* &#10094; */}
                {/* <p className="arrow right" onClick={handleNext}>&#10095;</p> */}
                <KeyboardArrowRightIcon className="arrow right" onClick={handleNext} />
            </div>
            <div className="circles">
                {images.map((_, index) => (
                    <div
                        key={index}
                        className={`circle ${currentIndex === index ? 'active' : ''}`}
                        onClick={() => handleCircleClick(index)}
                    />
                ))}
            </div>
        </div>
    );
};

export default ImageCarousel;
