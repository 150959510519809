import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Contentimages from "../components/Contentimages";
import ProtfolioNavigation from "../components/ProtfolioNavigation";

import Img1 from "./../assets/images/TorysLPP/1.jpg";
import Img2 from "./../assets/images/TorysLPP/2.jpg";
import Img3 from "./../assets/images/TorysLPP/3.jpg";
import Img4 from "./../assets/images/TorysLPP/4.jpg";
import Img5 from "./../assets/images/TorysLPP/5.jpg";
import Img6 from "./../assets/images/TorysLPP/6.jpg";
import Img7 from "./../assets/images/TorysLPP/7.jpg";
import Img8 from "./../assets/images/TorysLPP/8.jpg";
import Img9 from "./../assets/images/TorysLPP/9.jpg";

import ProjectImg1 from "./../assets/images/TorysLPP/project_1.jpg";
import ProjectImg2 from "./../assets/images/projectsImages/0098-MidwestLocation-1.jpg"
import ProjectImg3 from "./../assets/images/TorysLPP/project_3.jpg";
import ProjectImg4 from "./../assets/images/TorysLPP/project_4.jpg";
import RelatedProjects from "../components/RelatedProjects";
import PostRealetedDates from "../components/PostRealetdDates";


const TorysLPP = () => {
    const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8,Img9];

    const projects = [
        {
            title: "Field Law Calgary",
            firm: "Law",
            image: ProjectImg1,
            link: "/portfolio_page/field-law/",
        },
        {
            title: "Gowling WLG",
            firm: "Law",
            image: ProjectImg2,
            link: "/portfolio_page/gowling/",
        },
        {
            title: "Heenan Blaikie LLP",
            firm: "Law",
            image: ProjectImg3,
            link: "/portfolio_page/heenan-blaikie/",
        },
        {
            title: "Burnet, Duckworth & Palmer LLP",
            firm: "Law",
            image: ProjectImg4,
            link: "/portfolio_page/burnet-duckworth-palmer/",
        }
    ];

    const relatedDates = [
        {
            title: "Burnet, Duckworth & Palmer LLP",
            url: "/portfolio_page/burnet-duckworth-palmer/",
            date: "September 24, 2012",
        },
        {
            title: "Heenan Blaikie LLP",
            url: "/portfolio_page/heenan-blaikie/",
            date: "September 25, 2012",
        },
        {
            title: "Devon 3 Fitness Centre",
            url: "/portfolio_page/devon-3-fitness-centre/",
            date: "February 26, 2019",
        },
    ];


    return (
        <>
            <div className="parent-div">
                <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Box>
                        <Contentimages
                            heading={"Torys LLP"}
                            tab={"Calgary, Alberta"}
                            imageArray={imageArray}
                        >
                        </Contentimages>
                        <Grid2 container paddingBottom={5}></Grid2>
                        <PostRealetedDates relatedDates={relatedDates} />
                        <ProtfolioNavigation
                            backUrl="/portfolio_page/arcan-resources/"
                            nextUrl="/portfolio_page/petrobakken/"
                        />

                        <RelatedProjects
                            projects={projects}
                        />

                    </Box>
                </Container>
            </div>
        </>
    )
}


export default TorysLPP