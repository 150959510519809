import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/sharatoneau/sharaton_first.jpg";
import Img2 from "./../assets/images/sharatoneau/sharaton_second.jpg";
import Img3 from "./../assets/images/sharatoneau/sharaton_third.jpg";
import Img4 from "./../assets/images/sharatoneau/sharaton_four.jpg";
import Img5 from "./../assets/images/sharatoneau/sharaton_five.jpg";
import Img6 from "./../assets/images/sharatoneau/sharaton_six.jpg";
import Img7 from "./../assets/images/sharatoneau/sharaton_seven.jpg";
import Img8 from "./../assets/images/sharatoneau/sharaton_eight.jpg";
import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import { Link } from "react-router-dom";



const Sheratoneau = () => {
    const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8];

    const relatedDates = [
        {
            title: "MEG Energy",
            url: "/portfolio_page/meg-energy/",
            date: "May 18, 2017"
        },
        {
            title: "Alberta Utilities Commission",
            url: "/portfolio_page/alberta-utilities-commission/",
            date: "April 20, 2018"
        },
        {
            title: "Burnet, Duckworth & Palmer LLP",
            url: "/portfolio_page/burnet-duckworth-palmer/",
            date: "September 24, 2012"
        }
    ]


    return (
        <>
            <div className="parent-div">
                <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Box>
                        <Contentimages
                            tab={"Calgary, Alberta"}
                            heading={"Sheraton Eau Claire"}
                            imageArray={imageArray}
                        >
                            With a mission to update the lobby ceilings at Sheraton Suites Calgary Eau Claire, we were pleased to work under contract to <Link to={"https://ryanmurphyconstruction.com/"} target="_blank" className="navigation-link">Ryan Murphy Construction.</Link>
                            {" "}Designed by <Link to={"https://bharchitects.com/en/"} target="_blank" className="navigation-link">B+H Architects,</Link> the new ceilings create a striking statement in the spacious lobby.
                            Besides drywall ceilings and light coves, our scope encompassed the supply and install of recessed MDF access panels and Soundscapes Shapes panels.
                            The white <Link to={"https://www.armstrongceilings.com/commercial/en/commercial-ceilings-walls/soundscapes-shapes-ceiling-clouds.html"} target="_blank" className="navigation-link">Soundscapes Shapes panels by Armstrong</Link> serve a double-duty: a creative design element as well as much-needed acoustic control in the large hotel lobby.
                            The result is a bright, expansive, contemporary ceiling that will serve Sheraton’s clientele for many years to come.
                        </Contentimages>
                        <Grid2 container paddingBottom={10}></Grid2>
                        <PostRealetedDates relatedDates={relatedDates} />
                        <ProtfolioNavigation
                            backUrl="/portfolio_page/harry-hays-parks-canada-2/"
                            nextUrl="/portfolio_page/alberta-utilities-commission/"
                        />
                    </Box>
                </Container>
            </div>
        </>


    );
};

export default Sheratoneau;
