import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/cisco/cisco_first.jpg";
import Img2 from "./../assets/images/cisco/cisco_second.jpg";
import Img3 from "./../assets/images/cisco/cisco_third.jpg";
import Img4 from "./../assets/images/cisco/cisco_four.jpg";
import Img5 from "./../assets/images/cisco/cisco_five.jpg";
import Img6 from "./../assets/images/cisco/cisco_six.jpg";
import Img7 from "./../assets/images/cisco/cisco_seven.jpg";
import Img8 from "./../assets/images/cisco/cisco_eight.jpg";
import Img9 from "./../assets/images/cisco/cisco_nine.jpg";
import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import { Link } from "react-router-dom";



const Ciscosystems = () => {
    const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9];

    const relatedDates = [
        {
            title: "CertainTeed",
            url: "/portfolio_page/certainteed/",
            date: "March 7, 2018"
        },
        {
            title: "Pembina Pipeline",
            url: "/portfolio_page/pembina/",
            date: "September 23, 2015"
        },
        {
            title: "Gulf Canada Square Food Court",
            url: "/portfolio_page/gulf-canada-square/",
            date: "September 25, 2016"
        }
    ]


    return (
        <>
            <div className="parent-div">
                <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Box>
                        <Contentimages
                            tab={"Calgary, Alberta"}
                            heading={"Cisco"}
                            imageArray={imageArray}
                        >
                            It’s only right that an up-and-coming technology company should have an inspirational office space. <Link to={"https://bharchitects.com/en/"} target="_blank" className="navigation-link"></Link>
                            We are awed by the new Cisco space, especially the beautiful <Link to={"https://www.certainteed.com/products/ceiling-wall-systems-products"} target="_blank" className="navigation-link">Hunter Douglas High Profile Series™ Straight Baffle Ceiling</Link>that is featured throughout the office.
                            Made of custom aluminum bar, it weighs 17,000 lbs! To get it into the space for installation required a team of Midwesters and a crane driver closing off the street and booming it through a window,
                            strategically placing it throughout the floor so it wouldn’t break through.
                        </Contentimages>
                        <Grid2 container paddingBottom={10}></Grid2>
                        <PostRealetedDates relatedDates={relatedDates} />
                        <ProtfolioNavigation
                            backUrl="/portfolio_page/meg-energy/"
                            nextUrl="/portfolio_page/field-law/"
                        />

                    </Box>
                </Container>
            </div>
        </>


    );
};

export default Ciscosystems;
