import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/natinalMusic/national_first.jpg";
import Img2 from "./../assets/images/natinalMusic/national_second.jpg";
import Img3 from "./../assets/images/natinalMusic/national_third.jpg";
import Img4 from "./../assets/images/natinalMusic/national_four.jpg";
import Img5 from "./../assets/images/natinalMusic/national_five.jpg";
import Img6 from "./../assets/images/natinalMusic/national_six.jpg";
import Img7 from "./../assets/images/natinalMusic/national_seven.jpg";
import Img8 from "./../assets/images/natinalMusic/national_eight.jpg";
import Img9 from "./../assets/images/natinalMusic/national_nine.jpg";

import Project_first from "./../assets/images/relatedprojects/childern.jpg";

import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import RelatedProjects from "../components/RelatedProjects";


const NatinalMusic = () => {
  const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9];

  const relatedDates = [
    {
      title: "Burnet, Duckworth & Palmer LLP",
      url: "/portfolio_page/burnet-duckworth-palmer/",
      date: "September 24, 2012",
    },
    {
      title: "Esker Art Gallery",
      url: "/portfolio_page/esker-art-gallery-2/",
      date: "March 24, 2018",
    },
    {
      title: "Devon 3 Fitness Centre",
      url: "/portfolio_page/devon-3-fitness-centre/",
      date: "February 26, 2019",
    },
  ];

  const projects = [
    {
      title: "Children’s Discovery Museum",
      firm: "Tourism",
      image: Project_first,
      link: "/portfolio_page/childrens-discovery-museum/",
    },
  ];

  const onUrlClick = (url) => {
    window.open(url, '_blank');
  }

  return (
    <>
      <div className="parent-div">
        <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Box>
            <Contentimages
              tab={"Calgary, Alberta"}
              heading={"National Music Center"}
              imageArray={imageArray}>
              MidWest Group is pleased to have participated in this challenging
              and labour intensive project. Our interior team applied
              <span className="portfolio_url_text"
                onClick={() => {
                  onUrlClick("https://www.starsilent.com/")
                }}
              > Star Silent </span>
              to the convex enormadome ceiling. There was 5,000 sq.ft of
              seamless plaster across the enormadome that required 16
              tradespeople to simultaneously apply and was a significant
              accomplishment for our team and the industry. For the vessel
              walls, BIM modelling was utilized to achieve compound curves and
              natural slopes.
            </Contentimages>

            <Grid2 container paddingBottom={10}></Grid2>
            <PostRealetedDates relatedDates={relatedDates} />
            <ProtfolioNavigation
              backUrl="/portfolio_page/simons/"
              nextUrl="/portfolio_page/gulf-canada-square/"
            />
            <RelatedProjects projects={projects} />
          </Box>
        </Container>
        
      </div>
    </>
  );
};

export default NatinalMusic;
