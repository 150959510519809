import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import "./../assets/css/ImagePreview.css";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '60vw',
    height: '90vh',
    bgcolor: 'transparent',
    overflow: 'hidden',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    outline: 'none'
};

const imageStyle = {
    flex: 1, // Make the image container take the available space
    width: '100%',
    height: '100%',
    objectFit: 'fill',
};

const footerStyle = {
    height: '5%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    backgroundColor: 'none', // Darker background for better visibility
    color: 'white', // Text color for visibility
    zIndex: 10, // Ensure it's above the image
};

export default function ImagePreview(props) {
    const [currentIndex, setCurrentIndex] = useState(props?.currentIndex ?? 0);
    const [fade, setFade] = useState(false);

    const handleNext = () => {
        setFade(true);
        setTimeout(() => {
            setCurrentIndex((currentIndex + 1) % props?.images.length);
            setFade(false);
        }, 500);
    };

    const handlePrev = () => {
        setFade(true);
        setTimeout(() => {
            setCurrentIndex((currentIndex - 1 + props?.images.length) % props?.images.length);
            setFade(false);
        }, 500);
    };

    if (!props?.open) {
        return null;
    }

    return (
        <Modal
            open={props?.open}
            onClose={props?.handleClose}
            disableScrollLock
            sx={{ outline: 'none' }}
        >
            <Box sx={style} className="image-preview-box">
                <div className='image_preview_container' style={{ position: 'relative', width: '100%', height: '95%' }}>
                    <img
                        src={props?.images[currentIndex]}
                        alt=""
                        style={imageStyle}
                        className={`preview-carousel-image ${fade ? 'fade' : ''}`}
                    />

                    <KeyboardArrowLeftIcon className="arrow left" onClick={handlePrev} />
                    <KeyboardArrowRightIcon className="arrow right" onClick={handleNext} />
                </div>

                <div style={footerStyle}>
                    <KeyboardArrowLeftIcon onClick={handlePrev} className='preview_arrow' />
                    <span style={{ margin: '0 10px' }} className='preview_image_index'>
                        {currentIndex + 1}/{props.images.length}
                    </span>
                    <KeyboardArrowRightIcon onClick={handleNext} className='preview_arrow' />

                    <div style={{ marginLeft: 'auto', cursor: 'pointer' }} onClick={props.handleClose}>
                        <IconButton onClick={props.handleClose}>
                            <CloseIcon sx={{ color: "white" }} />
                        </IconButton>

                    </div>
                </div>
            </Box>
        </Modal>
    );
}
