import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/gowling/gowling_first.jpg";
import Img2 from "./../assets/images/gowling/gowling_second.jpg";
import Img3 from "./../assets/images/gowling/gowling_third.jpg";
import Img4 from "./../assets/images/gowling/gowling_four.jpg";
import Img5 from "./../assets/images/gowling/gowling_five.jpg";

import Project_first from "../assets/images/relatedprojects/field_law.jpg";
import Project_second from "../assets/images/relatedprojects/torys.jpg";
import Project_third from "../assets/images/relatedprojects/heenan.jpg";
import Project_four from "../assets/images/relatedprojects/burnet.jpg";

import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import RelatedProjects from "../components/RelatedProjects";
import { Link } from "react-router-dom";


const Gowlingwlg = () => {
    const imageArray = [Img1, Img2, Img3, Img4, Img5];

    const relatedDates = [
        {
            title: "Nexen Wellness",
            url: "/portfolio_page/nexen-wellness/",
            date: "September 24, 2013",
        },
        {
            title: "TransCanada Interior Renovation",
            url: "/portfolio_page/transcanada-interior-renovation/",
            date: "April 10, 2019",
        },
        {
            title: "Field Law Calgary",
            url: "portfolio_page/field-law/",
            date: "April 8, 2017",
        },
    ];

    const projects = [
        {
            title: "Field Law Calgary",
            firm: "Law",
            image: Project_first, // Placeholder image URL
            link: "/portfolio_page/field-law/", // URL path or external link
        },
        {
            title: "Torys LLP",
            firm: "Law",
            image: Project_second,
            link: "/portfolio_page/torys/",
        },
        {
            title: "Heenan Blaikie LLP",
            firm: "Law",
            image: Project_third,
            link: "/portfolio_page/heenan-blaikie/",
        },
        {
            title: "Burnet, Duckworth & Palmer LLP",
            firm: "Law",
            image: Project_four,
            link: "/portfolio_page/burnet-duckworth-palmer/",
        },
    ];

    return (
        <>
            <div className="parent-div">
                <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Box>
                        <Contentimages
                            tab={"Calgary, Alberta"}
                            heading={"Gowling WLG"}
                            imageArray={imageArray}
                        >
                            MidWest was delighted to take on the multi-floor Gowling WLG Law Office renovation in Canada Trust Tower.
                            A level 5 finish was applied on all of the <Link to={"https://www.armstrongceilings.com/commercial/en/commercial-ceilings-walls/optima-vector-ceiling-tiles.html"} className="navigation-link">Armstrong Optima Vector</Link>{" "}ceiling tile in the reception area,
                            and the unique ceiling reveals throughout the floors make for an appealing design.
                        </Contentimages>
                        <Grid2 container paddingBottom={10}></Grid2>
                        <PostRealetedDates relatedDates={relatedDates} />
                        <ProtfolioNavigation
                            backUrl="/portfolio_page/walden-heights/"
                            nextUrl="/portfolio_page/pembina/"
                        />
                        <RelatedProjects
                            projects={projects}
                        />
                    </Box>
                </Container>
            </div>
        </>


    );
};

export default Gowlingwlg;
