import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/eskar/esker_first.png";
import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import { Link } from "react-router-dom";



const Eskerarts = () => {
    const imageArray = [Img1];

    const relatedDates = [
        {
            title: "Devon 3 Fitness Centre",
            url: "/portfolio_page/devon-3-fitness-centre/",
            date: "February 26, 2019"
        },
        {
            title: "Sheraton Eau Claire",
            url: "/portfolio_page/sheraton-eau-claire/",
            date: "April 25, 2018"
        },
        {
            title: "Field Law Calgary",
            url: "/portfolio_page/field-law/",
            date: "April 8, 2017"
        }
    ]


    return (
        <>
            <div className="parent-div">
                <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Box>
                        <Contentimages
                            tab={"Calgary, Alberta"}
                            heading={"Esker Art Gallery"}
                            imageArray={imageArray}
                        >
                            A privately funded gallery in Calgary,{" "}<Link to={"https://eskerfoundation.com/"} target="_blank" className="navigation-link">Esker Foundation Contemporary Art Gallery</Link> seeks to spark dialogue and awareness of contemporary art.
                            The foundation is committed to the development of new work and research in the field,
                            and displays pieces reflecting local, national, and international culture. With exhibits changing quarterly,
                            the MidWest Group Rapid Response Team is proud to partner with the Esker Foundation to adjust their space as required.
                            Most recently, MidWest completed the framing, boarding, and taping of 56’ x 10’ of wall space, and 520 sq.ft. of drywall ceiling.
                            The wall serves as a backdrop for the exhibit <Link to={"https://eskerfoundation.com/exhibition/kapwani-kiwanga/"} style={{fontStyle:"italic"}} target="_blank" className="navigation-link">A wall is just a wall (and nothing more at all) by Kapwani Kiwanga</Link>{" "}that will be on display until May 6, 2018.
                            Check out Esker Foundation’s website to <Link to={"https://eskerfoundation.com/exhibition/kapwani-kiwanga/"}  target="_blank" className="navigation-link">learn more</Link> about this thought-provoking exhibit, or <Link to={"https://eskerfoundation.com/visit/"} target="_blank" className="navigation-link">visit the gallery</Link> (admission is free!)
                        </Contentimages>
                        <Grid2 container paddingBottom={10}></Grid2>
                        <PostRealetedDates relatedDates={relatedDates} />
                        <ProtfolioNavigation
                            backUrl="/portfolio_page/alberta-utilities-commission/"
                            nextUrl="/portfolio_page/certainteed/"
                        />

                    </Box>
                </Container>
            </div>
        </>


    );
};

export default Eskerarts;
