import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import Img1 from "./../assets/images/HeenaBlaikie/1.jpg";
import Img2 from "./../assets/images/HeenaBlaikie/2.jpg";
import Img3 from "./../assets/images/HeenaBlaikie/3.jpg";
import Img4 from "./../assets/images/HeenaBlaikie/4.jpg";
import Project_first from "./../assets/images/relatedprojects/field_law.jpg";
import Project_second from "./../assets/images/relatedprojects/gowling.jpg";
import Project_three from "./../assets/images/relatedprojects/torys.jpg";
import Project_four from "./../assets/images/relatedprojects/burnet.jpg";
import RelatedProjects from "../components/RelatedProjects";

const HeenaBlaikie = () => {

    const imageArray = [Img1, Img2, Img3, Img4];


    const relatedDates = [
        {
            title: "Burnet, Duckworth & Palmer LLP",
            url: "/portfolio_page/burnet-duckworth-palmer/",
            date: "September 24, 2012"
        },
        {
            title: "Torys LLP",
            url: "/portfolio_page/torys/",
            date: "September 27, 2012"
        },
        {
            title: "Devon 3 Fitness Centre",
            url: "/portfolio_page/devon-3-fitness-centre/",
            date: "February 26, 2019"
        }
    ]


    const projects = [
        {
            title: "Field Law Calgary",
            firm: "Law",
            image: Project_first,
            link: "/portfolio_page/field-law/",
        },
        {
            title: "Gowling WLG",
            firm: "Law",
            image: Project_second,
            link: "/portfolio_page/gowling/",
        },
        {
            title: "Torys LLP",
            firm: "Law",
            image: Project_three,
            link: "/portfolio_page/torys/",
        },
        {
            title: "Burnet, Duckworth & Palmer LLP",
            firm: "Law",
            image: Project_four,
            link: "/portfolio_page/burnet-duckworth-palmer/",
        }
    ];


    return (
        <>
            <div className="parent-div">
                <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Box>
                        <Contentimages
                            heading={"Heenan Blaikie LLP"}
                            tab={"Calgary, Alberta"}
                            imageArray={imageArray}
                        >
                        </Contentimages>
                        <Grid2 container paddingBottom={5}></Grid2>
                        <PostRealetedDates relatedDates={relatedDates} />
                        <ProtfolioNavigation
                            backUrl="/portfolio_page/petrobakken/"
                            nextUrl="/portfolio_page/burnet-duckworth-palmer/"
                        />

                        <RelatedProjects
                            projects={projects}
                        />

                    </Box>
                </Container>
            </div>
        </>
    )
}

export default HeenaBlaikie