import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/imperialOil/imperial_first.jpg";
import Img2 from "./../assets/images/imperialOil/imperial_second.jpg";
import Projectfirst from "./../assets/images/projectsImages/IMG_6229_edited.jpg";
import Projectsecond from "./../assets/images/relatedprojects/pembina.jpg";
import Projectthree from "./../assets/images/relatedprojects/cenovus.jpg";
import Projectfour from "./../assets/images/relatedprojects/bow.jpg";
import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import RelatedProjects from "../components/RelatedProjects";


const ImperialOil = () => {
  const imageArray = [Img1, Img2];

  const relatedDates = [
    {
      title: "MEG Energy",
      url: "/portfolio_page/meg-energy/",
      date: "May 18, 2017",
    },
    {
      title: "Burnet, Duckworth & Palmer LLP",
      url: "/portfolio_page/burnet-duckworth-palmer/",
      date: "September 24, 2012",
    },
    {
      title: "Nordstrom Chinook",
      url: "/portfolio_page/nordstrom/",
      date: "March 25, 2016",
    },
  ];

  const projects = [
    {
      title: "MEG Energy",
      firm: "Oil & Gas",
      image: Projectfirst,
      link: "/portfolio_page/meg-energy/",
    },
    {
      title: "Pembina Pipeline",
      firm: "Oil & Gas",
      image: Projectsecond,
      link: "/portfolio_page/pembina/",
    },
    {
      title: "Cenovus Energy",
      firm: "Oil & Gas",
      image: Projectthree,
      link: "/portfolio_page/cenovus-energy/",
    },
    {
      title: "Bow Tower",
      firm: "Oil & Gas",
      image: Projectfour,
      link: "/portfolio_page/bow-tower/",
    },
  ];

  const onUrlClick = (url) => {
    window.open(url, '_blank');
  }

  return (
    <>
      <div className="parent-div">
        <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Box>
            <Contentimages
              tab={"Calgary, Alberta"}
              heading={"Imperial Oil – Quarry Park"}
              imageArray={imageArray}>
              MidWest Group had involvement in five buildings in the South
              Campus Quarry Park Development for Imperial Oil. Our attention to
              detail has allowed us to excel on this project in providing many
              reveals, custom T-Bar ceilings and custom
              <span className="portfolio_url_text"
                onClick={() => {
                  onUrlClick("https://www.hunterdouglasarchitectural.com/ceilings/index.jsp")
                }}
              >
                {" "}
                Hunter Douglas Ceilings.{" "}
              </span>
              There are many unique parts to this project including an
              intricately designed cafeteria with multi-tier bulkheads and
              high-end conference rooms with skyfold partitions.
            </Contentimages>

            <Grid2 container paddingBottom={10}></Grid2>
            <PostRealetedDates relatedDates={relatedDates} />
            <ProtfolioNavigation
              backUrl="/portfolio_page/nordstrom/"
              nextUrl="/portfolio_page/walden-heights/"
            />
            <RelatedProjects projects={projects} />
          </Box>
        </Container>
        
      </div>
    </>
  );
};

export default ImperialOil;
