import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import Img1 from "./../assets/images/AshleyFurniture/1.jpg";
import Img2 from "./../assets/images/AshleyFurniture/2.jpg";
import Img3 from "./../assets/images/AshleyFurniture/3.jpg";
import Img4 from "./../assets/images/AshleyFurniture/4.jpg";
import Img5 from "./../assets/images/AshleyFurniture/5.jpg";
import Img6 from "./../assets/images/AshleyFurniture/6.jpg";
import Related_first from "../assets/images/relatedprojects/sage_hill.png";
import Related_second from "../assets/images/relatedprojects/simons.jpg";
import Related_third from "../assets/images/relatedprojects/gulf_canada.jpg";
import Related_four from "../assets/images/relatedprojects/nordstrom.jpg";
import RelatedProjects from "../components/RelatedProjects";


const AshleyFurniture = () => {

    const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6];


    const relatedDates = [
        {
            title: "Burnet, Duckworth & Palmer LLP",
            url: "/portfolio_page/burnet-duckworth-palmer/",
            date: "September 24, 2012"
        },
        {
            title: "Devon 3 Fitness Centre",
            url: "/portfolio_page/devon-3-fitness-centre/",
            date: "February 26, 2019"
        },
        {
            title: "Simons",
            url: "/portfolio_page/simons/",
            date: "April 7, 2017"
        }
    ];

    const projects = [
        {
            title: "Sage Hill Quarter",
            firm: "Retail",
            image: Related_first, // Placeholder image URL
            link: "/portfolio_page/sage-hill-quarter/", // URL path or external link
        },
        {
            title: "Simons",
            firm: "Retail",
            image: Related_second, // Placeholder image URL
            link: "/portfolio_page/simons/", // URL path or external link
        },
        {
            title: "Gulf Canada Square Food Court",
            firm: "Retail",
            image: Related_third, // Placeholder image URL
            link: "/portfolio_page/gulf-canada-square/", // URL path or external link
        },
        {
            title: "Nordstrom Chinook",
            firm: "Retail",
            image: Related_four, // Placeholder image URL
            link: "/portfolio_page/nordstrom/", // URL path or external link
        },
    ];

    const onUrlClick = (url) => {
        window.open(url, '_blank');
    }

    return (
        <>
            <div className="parent-div">
                <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Box>
                        <Contentimages
                            heading={"Ashley Furniture HomeStore"}
                            tab={"Calgary, Alberta"}
                            imageArray={imageArray}
                        >
                            MidWest Group constructed the interior drywall scope of a new Ashley Furniture HomeStore location under contract to {" "}
                            <span className="portfolio_url_text"
                                onClick={() => {
                                    onUrlClick("https://www.vcm.ca/")
                                }}
                            >VCM Construction</span>. {"  "}
                            <br />

                            Designed by

                            <span className="portfolio_url_text"
                                onClick={() => {
                                    onUrlClick("https://www.minadesign.ca/")
                                }}
                            > Mina Design Group</span> {" "}

                            and

                            <span className="portfolio_url_text"
                                onClick={() => {
                                    onUrlClick("https://chorny.com/Home.html")
                                }}
                            > Chorny Associates Architects</span>

                            , the space received a complete upgrade from the previous tenant,
                            and the result is a well-developed showcase of Ashley’s range of product.

                            <br />

                            Our scope included drywall partitions, ceilings, build-outs and furring, installation of steel door frames,
                            and T-bar ceilings. All showroom partition corners are finished with rounded bead, a small detail
                            with a large impact on the overall design.

                            <br />
                            The Ashley Furniture HomeStore is located in Calgary’s Brentwood Village Mall.

                        </Contentimages>
                        <Grid2 container paddingBottom={10}></Grid2>
                        <PostRealetedDates relatedDates={relatedDates} />
                        <ProtfolioNavigation
                            backUrl="/portfolio_page/devon-3-fitness-centre/"
                            nextUrl="/portfolio_page/childrens-discovery-museum/"
                        />
                        <RelatedProjects
                            projects={projects}
                        />
                    </Box>
                </Container>
            </div>
        </>
    )
}

export default AshleyFurniture