import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import Footer from './components/Footer';
import Home from './pages/Home';
import Scope from './pages/Scope';
import ProjectHome from './pages/ProjectHome';
import Safety from './pages/Safety';
import Contact from './pages/Contact';
import TranscanadaInterior from './pages/TranscanadaInterior';
import DevonFitness from './pages/DevonFitness';
import AshleyFurniture from './pages/AshleyFurniture';
import HarryHays from './pages/HarryHays';
import SageHillQuarter from './pages/SageHillQuarter';
import ChildernDiscovery from './pages/ChildernDiscovery';
import HeenaBlaikie from './pages/HeenaBlaikie';
import ArcanResources from './pages/ArcanResources';
import FieldLaw from "./pages/FieldLaw";
import Simons from "./pages/Simons";
import NatinalMusic from "./pages/NatinalMusic";
import GulfCanada from "./pages/GulfCanada";
import Nordstrom from "./pages/Nordstrom";
import ImperialOil from "./pages/ImperialOil";
import BurnetDuckworth from "./pages/BurnetDuckworth";
import TorysLPP from './pages/TorysLPP';
import PetrobakkenEnergy from './pages/PetrobakkenEnergy';
import Sheratoneau from './pages/Sheratoneau';
import Albertautilities from './pages/Albertautilities';
import Eskerarts from './pages/Eskerarts';
import Certainteed from './pages/Certainteed';
import Megenergy from './pages/Megenergy';
import Ciscosystems from './pages/Ciscosystems';
import Waldenheights from './pages/Waldenheights';
import Gowlingwlg from './pages/Gowlingwlg';
import Pembinapipline from './pages/Pembinapipline';
import Cenovus from './pages/Cenovus';
import Bowtower from './pages/Bowtower';
import Nexenwellness from './pages/Nexenwellness';
import About from './pages/About';
import Career from './pages/Career';
import ScrollToTop from './components/ScrollToTop';
import React, { useEffect, useState } from 'react';
import { IconButton } from '@mui/material';
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import DrawerAppBar from './components/MenuBar';

function App() {

  const [showScroll, setShowScroll] = useState(false);

  const location = useLocation();

  const formatTitle = (str) => {
    return str
      ?.split('-') // Split by dashes
      ?.map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
      .join(' '); // Join with spaces
  };

  useEffect(() => {
    let getPath = location.pathname.split("/").filter(Boolean).pop();
    if (typeof getPath !== "undefined") {
      document.title = formatTitle(getPath) + " | MidWest Group";
      console.log("logo", getPath)
    } else {
      document.title = "MidWest Group";
    }
  }, [location.pathname])

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScroll(true);
    } else {
      setShowScroll(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);


  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {showScroll && (
        <IconButton
          sx={{
            position: "fixed",
            bottom: 40,
            right: 40,
            padding: "10px",
            zIndex: 9999,
            backgroundColor: "rgba(95,140,39,1)",
            "&:hover": {
              backgroundColor: "rgba(95,140,39,1)",
              border: "1px solid #fff",
            },
          }}
          onClick={scrollToTop}>
          <ArrowUpwardIcon style={{ color: "#fff" }} />
        </IconButton>
      )}
      <DrawerAppBar>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/scope/" element={<Scope />} />
          <Route path="/projects/" element={<ProjectHome />} />
          <Route path="/safety/" element={<Safety />} />
          <Route path="/contact/" element={<Contact />} />
          <Route path="/careers/" element={<Career />} />
          <Route path="/portfolio_page/transcanada-interior-renovation/" element={<TranscanadaInterior />} />
          <Route path="/portfolio_page/devon-3-fitness-centre/" element={<DevonFitness />} />
          <Route path="/portfolio_page/ashley-furniture-homestore/" element={<AshleyFurniture />} />
          <Route path="/portfolio_page/childrens-discovery-museum/" element={<ChildernDiscovery />} />
          <Route path="/portfolio_page/sage-hill-quarter/" element={<SageHillQuarter />} />
          <Route path="/portfolio_page/harry-hays-parks-canada-2/" element={<HarryHays />} />
          <Route path="/portfolio_page/sheraton-eau-claire/" element={<Sheratoneau />} />
          <Route path="/portfolio_page/alberta-utilities-commission/" element={<Albertautilities />} />
          <Route path="/portfolio_page/esker-art-gallery-2/" element={<Eskerarts />} />
          <Route path="/portfolio_page/certainteed/" element={<Certainteed />} />
          <Route path="/portfolio_page/meg-energy/" element={<Megenergy />} />
          <Route path="/portfolio_page/cisco/" element={<Ciscosystems />} />
          <Route path="/portfolio_page/walden-heights/" element={<Waldenheights />} />
          <Route path="/portfolio_page/gowling/" element={<Gowlingwlg />} />
          <Route path="/portfolio_page/pembina/" element={<Pembinapipline />} />
          <Route path="/portfolio_page/cenovus-energy/" element={<Cenovus />} />
          <Route path="/portfolio_page/bow-tower/" element={<Bowtower />} />
          <Route path="/portfolio_page/nexen-wellness/" element={<Nexenwellness />} />
          <Route path="/portfolio_page/field-law/" element={<FieldLaw />} />
          <Route path="/portfolio_page/simons/" element={<Simons />} />
          <Route path="/portfolio_page/national-music-centre/" element={<NatinalMusic />} />
          <Route path="/portfolio_page/gulf-canada-square/" element={<GulfCanada />} />
          <Route path="/portfolio_page/nordstrom/" element={<Nordstrom />} />
          <Route path="/portfolio_page/imperial-oil-quarry-park/" element={<ImperialOil />} />
          <Route path="/portfolio_page/arcan-resources/" element={<ArcanResources />} />
          <Route path="/portfolio_page/torys/" element={<TorysLPP />} />
          <Route path="/portfolio_page/petrobakken/" element={<PetrobakkenEnergy />} />
          <Route path="/portfolio_page/heenan-blaikie/" element={<HeenaBlaikie />} />
          <Route path="/portfolio_page/burnet-duckworth-palmer/" element={<BurnetDuckworth />} />
        </Routes>
        <Footer />
      </DrawerAppBar>
      <ScrollToTop />
    </>
  );
}

export default App;