import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/nexen/nexen_first.jpg";
import Img2 from "./../assets/images/nexen/nexen_second.jpg";
import Img3 from "./../assets/images/nexen/nexen_third.jpg";
import Img4 from "./../assets/images/nexen/nexen_four.jpg";
import Img5 from "./../assets/images/nexen/nexen_five.jpg";
import Img6 from "./../assets/images/nexen/nexen_six.jpg";
import Img7 from "./../assets/images/nexen/nexen_seven.jpg";
import Img8 from "./../assets/images/nexen/nexen_eight.jpg";
import Img9 from "./../assets/images/nexen/nexen_nine.jpg";

import Project_first from "./../assets/images/projectsImages/IMG_6229_edited.jpg";
import Project_second from "./../assets/images/projectsImages/1.jpg";
import Project_third from "../assets/images/relatedprojects/pembina.jpg";
import Project_four from "../assets/images/relatedprojects/cenovus.jpg";

import Contentimages from "../components/Contentimages";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import RelatedProjects from "../components/RelatedProjects";



const Nexenwellness = () => {

  const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9];

  // const relatedDates = [];

  const projects = [
    {
      title: "MEG Energy",
      firm: "Oil & Gas",
      image: Project_first
      , // Placeholder image URL
      link: "/portfolio_page/meg-energy/", // URL path or external link
    },
    {
      title: "Imperial Oil – Quarry Park",
      firm: "Oil & Gas",
      image: Project_second,
      link: "/portfolio_page/imperial-oil-quarry-park/",
    },
    {
      title: "Pembina Pipeline",
      firm: "Oil & Gas",
      image: Project_third,
      link: "/portfolio_page/cenovus-energy/",
    },
    {
      title: "Cenovus Energy",
      firm: "Oil & Gas",
      image: Project_four,
      link: "/portfolio_page/cenovus-energy/",
    },
  ];

  return (
    <>
      <div className="parent-div">
        <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Box>
            <Contentimages
              tab={"Calgary, Alberta"}
              heading={"Nexen Wellness"}
              imageArray={imageArray}
            >
              Nexen Wellness Centre is a state-of-the-art facility and an inspiring representation of their commitment to employee health.
              Design entity DIALOG excelled in creating a space that welcomes and motivates while maintaining careful consideration of acoustic performance.
              <br></br>
              <br></br>
              Working alongside Cougar Contractors, the MidWest team’s scope included standard drywall partitions, and other unique products and services, including:
              <br></br>
              <br></br>
              <ul style={{ paddingLeft: "15px",marginBottom:"0px" }}>
                <li>Cutting and reusing salvaged ceiling tiles for wall insulation</li>
                <li>Installing a cement board suspended ceiling</li>
                <li>ModularArts® acoustic panels</li>
                <li>Armstrong Axiom Knife Edge ceiling trim, including sandblasting, radius, and custom finish</li>
                <li>Armstrong Techzone ceiling grid with Ultima tile</li>
                <li>Application of StarSilent acoustic plaster</li>
                <li>Layout and coordination for lighting and mechanical</li>
              </ul>
              <br></br>
              The project received a LEED Silver certification.
            </Contentimages>
            <Grid2 container paddingBottom={10}></Grid2>

            <ProtfolioNavigation
              backUrl="/portfolio_page/bow-tower/"
              nextUrl="/portfolio_page/arcan-resources/"
            />
            <RelatedProjects
              projects={projects}
            />
          </Box>
        </Container>
      </div>
    </>


  );
};

export default Nexenwellness;
