import React from 'react'
import "./../assets/css/Scope.css";
import { Box } from '@mui/material'
import ImageCarousel from '../components/ImageCarousel';
import ScopeImageStack from '../components/ScopeImageStack';

const Scope = () => {
    return (
        <Box sx={{ paddingBottom: 5 }}>
            <div className='scope_main_container'>
                <div className='scope_title_container'>
                    <h1 className='scope_title'>
                        Our Scope
                    </h1>
                </div>

                <div className="scope_bg_container">
                    <div className="scope_bg_title_container">
                        <ul className="scope-ul-margin slide-in-text">
                            <li className="scope_bg_title">Steel Stud Framing</li>
                            <li className="scope_bg_title">Drywall & Insulation </li>
                            <li className="scope_bg_title">Taping</li>
                            <li className="scope_bg_title">
                                Suspended, Acoustic, & Specialty Ceilings
                            </li>
                            <li className="scope_bg_title">
                                Curved, Radius, & Wave Walls and Bulkheads
                            </li>
                            <li className="scope_bg_title">Custom Architectural Features</li>
                        </ul>
                    </div>
                </div>

                <div className='scope_image_carousel_container'>
                    <ImageCarousel />
                </div>

                <div className='scope_image_content_container'>
                    <ScopeImageStack />
                </div>
            </div>

        </Box>
    )
}

export default Scope