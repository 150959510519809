import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/gulfCanada/gulf_first.jpg";
import Img2 from "./../assets/images/gulfCanada/gulf_second.jpg";
import Img3 from "./../assets/images/gulfCanada/gulf_third.jpg";
import Img4 from "./../assets/images/gulfCanada/gulf_four.jpg";
import Img5 from "./../assets/images/gulfCanada/gulf_five.jpg";
import Img6 from "./../assets/images/gulfCanada/gulf_six.jpg";
import Img7 from "./../assets/images/gulfCanada/gulf_seven.jpg";
import Img8 from "./../assets/images/gulfCanada/gulf_eight.jpg";
import Img9 from "./../assets/images/gulfCanada/gulf_nine.jpg";

import Project_first from "./../assets/images/relatedprojects/ashley_furniture.jpg";
import Project_second from "./../assets/images/relatedprojects/sage_hill.png";
import Project_third from "./../assets/images/relatedprojects/simons.jpg";
import Project_four from "./../assets/images/relatedprojects/nordstrom.jpg";

import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import RelatedProjects from "../components/RelatedProjects";


const GulfCanada = () => {
  const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9];

  const relatedDates = [
    {
      title: "Esker Art Gallery",
      url: "/portfolio_page/esker-art-gallery-2/",
      date: "March 24, 2018",
    },
    {
      title: "National Music Centre",
      url: "/portfolio_page/national-music-centre/",
      date: "January 21, 2017",
    },
    {
      title: "Devon 3 Fitness Centre",
      url: "/portfolio_page/devon-3-fitness-centre/",
      date: "February 26, 2019",
    },
  ];

  const projects = [
    {
      title: "Ashley Furniture HomeStore",
      firm: "Retail",
      image: Project_first,
      link: "/portfolio_page/ashley-furniture-homestore/",
    },
    {
      title: "Sage Hill Quarter",
      firm: "Retail",
      image: Project_second,

      link: "/portfolio_page/sage-hill-quarter/",
    },
    {
      title: "Simons",
      firm: "Retail",
      image: Project_third,
      link: "/portfolio_page/simons/",
    },
    {
      title: "Nordstrom Chinook",
      firm: "Retail",
      image: Project_four,
      link: "/portfolio_page/nordstrom/",
    },
  ];

  const onUrlClick = (url) => {
    window.open(url, '_blank');
  }

  return (
    <>
      <div className="parent-div">
        <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Box>
            <Contentimages
              tab={"Calgary, Alberta"}
              heading={"Gulf Canada Square"}
              imageArray={imageArray}>
              Gulf Canada Square is a 20-storey office tower with 1,145,000
              sq.ft. It is located in the heart of downtown Calgary. MidWest’s
              scope of work was inside the food court and included the supply
              and install of a custom wood panel ceiling. Our team provided
              supply and install in an occupied space, 3,400 sq.ft of {" "}
              <span className="portfolio_url_text"
                onClick={() => {
                  onUrlClick("https://www.armstrongceilings.com/commercial/en-ca/commercial-ceilings-walls/wood-ceilings.html")
                }}
              >
                Armstrong Custom Woodwork Panels
              </span>
              , and 234 large oval wood panels in a cherry finish.
            </Contentimages>

            <Grid2 container paddingBottom={10}></Grid2>
            <PostRealetedDates relatedDates={relatedDates} />
            <ProtfolioNavigation
              backUrl="/portfolio_page/national-music-centre/"
              nextUrl="/portfolio_page/nordstrom/"
            />
            <RelatedProjects projects={projects} />
          </Box>
        </Container>
        
      </div>
    </>
  );
};

export default GulfCanada;
