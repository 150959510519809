import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/meg/meg_first.jpg";
import Img2 from "./../assets/images/meg/meg_second.jpg";
import Img3 from "./../assets/images/meg/meg_third.jpg";
import Img4 from "./../assets/images/meg/meg_four.jpg";
import Img5 from "./../assets/images/meg/meg_five.jpg";
import Img6 from "./../assets/images/meg/meg_six.jpg";
import Img7 from "./../assets/images/meg/meg_seven.jpg";
import Img8 from "./../assets/images/meg/meg_eight.jpg";
import Projectfirst from "./../assets/images/projectsImages/1.jpg"
import Projectsecond from "./../assets/images/relatedprojects/pembina.jpg";
import Projectthird from "./../assets/images/relatedprojects/cenovus.jpg";
import Projectfour from "./../assets/images/relatedprojects/bow.jpg";
import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import RelatedProjects from "../components/RelatedProjects";
import { Link } from "react-router-dom";


const Megenergy = () => {
    const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8];

    const relatedDates = [
        {
            title: "Burnet, Duckworth & Palmer LLP",
            url: "/portfolio_page/burnet-duckworth-palmer/",
            date: "September 24, 2012"
        },
        {
            title: "Nordstrom Chinook",
            url: "/portfolio_page/nordstrom/",
            date: "March 25, 2016"
        },
        {
            title: "Sheraton Eau Claire",
            url: "/portfolio_page/sheraton-eau-claire/",
            date: "April 25, 2018"
        }
    ]

    const projects = [
        {
            title: "Imperial Oil – Quarry Park",
            firm: "Oil & Gas",
            image: Projectfirst
            , // Placeholder image URL
            link: "/portfolio_page/imperial-oil-quarry-park/", // URL path or external link
        },
        {
            title: "Pembina Pipeline",
            firm: "Oil & Gas",
            image: Projectsecond,
            link: "/portfolio_page/pembina/", // URL path or external link
        },
        {
            title: "Cenovus Energy",
            firm: "Oil & Gas",
            image: Projectthird,
            link: "/portfolio_page/cenovus-energy/",
        },
        {
            title: "Bow Tower",
            firm: "Oil & Gas",
            image: Projectfour,
            link: "/portfolio_page/bow-tower/",
        },
    ];

    return (
        <>
            <div className="parent-div">
                <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Box>
                        <Contentimages
                            tab={"Calgary, Alberta"}
                            heading={"MEG Energy"}
                            imageArray={imageArray}
                          >
                            MidWest Group was pleased to be part of the MEG Energy project run by general contractor <Link to={"https://govanbrown.com/"} target="_blank" className="navigation-link">Govan Brown.</Link>
                            {" "}Located in Eau Claire Tower, MidWest’s scope encompassed 5 floors of drywall partitions, bulkheads,
                            ceilings, and internal stairwells. Designer <Link to={"https://bharchitects.com/en/"} target="_blank" className="navigation-link">B+H Architects</Link>  chose statement ceilings throughout MEG’s space,
                            including wood ceiling panels and perhaps our favourite: <Link to={"https://www.armstrongceilings.com/commercial/en/commercial-ceilings-walls/tincraft-ceiling-tiles.html"} target="_blank" className="navigation-link">Tincraft </Link>Bronze ceilings in the interview rooms, complemented
                            by electric fireplaces.
                        </Contentimages>
                        <Grid2 container paddingBottom={10}></Grid2>
                        <PostRealetedDates relatedDates={relatedDates} />
                        <ProtfolioNavigation
                            backUrl="/portfolio_page/certainteed/"
                            nextUrl="/portfolio_page/cisco/"
                        />
                        <RelatedProjects
                            projects={projects}
                        />
                    </Box>
                </Container>
            </div>
        </>


    );
};

export default Megenergy;
