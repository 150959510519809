import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/midwes-transcanda/1.jpg";
import Img2 from "./../assets/images/midwes-transcanda/2.jpg";
import Img3 from "./../assets/images/midwes-transcanda/3.jpg";
import Img4 from "./../assets/images/midwes-transcanda/4.jpg";
import Img5 from "./../assets/images/midwes-transcanda/5.jpg";
import Img6 from "./../assets/images/midwes-transcanda/6.jpg";
import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";


const TranscanadaInterior = () => {
  const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6];

  const relatedDates = [
    {
      title: "CertainTeed",
      url: "/portfolio_page/certainteed/",
      date: "March 7, 2018"
    },
    {
      title: "Gowling WLG",
      url: "/portfolio_page/gowling/",
      date: "September 26, 2015"
    },
    {
      title: "National Music Centre",
      url: "/portfolio_page/national-music-centre/",
      date: "January 21, 2017"
    }
  ]



  return (
    <>
      <div className="parent-div">
        <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Box>
            <Contentimages
              tab={"Calgary, Alberta"}
              heading={"TransCanada Interior Renovation"}
              imageArray={imageArray}
            >
              Under  contract to Magnus Interiors, MidWest Group recently completed the drywall ceiling, bulkhead, partition, and acoustic ceiling scope
              for TransCanada Tower’s newly renovated meeting floor. The project encompasses
              numerous meeting, collaboration, and breakout rooms. Interior design studio,
              Mode, excelled in creating an attractive and functional space, integrating
              numerous sound absorption methods between rooms.
            </Contentimages>
            <Grid2 container paddingBottom={10}></Grid2>
            <PostRealetedDates relatedDates={relatedDates} />
            <ProtfolioNavigation
              nextUrl="/portfolio_page/devon-3-fitness-centre/"
            />
          </Box>
        </Container>
      </div>
    </>


  );
};

export default TranscanadaInterior;
