import { Box, Container, Typography } from "@mui/material";

export default function About() {
    return(
        <>
        <Container
        sx={{
            minHeight: "63vh",
            display: "flex",
            flexDirection: "column",
          }}
          >
        <Box sx={{flex:"1", paddingTop: 15}}>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            variant="h2"
            component="h2"
            style={{ fontWeight: "600" }}
            gutterBottom
            className="Main-content">
            About MidWest
          </Typography>
        </Box>
        <p className="aboutparagraph">
        The MidWest Group, now part of the Thermal Systems group of companies, has over 35 years of experience in the construction industry.<br/> We are considered leaders, specializing in walls and ceilings, with a goal of being the best subcontractor on site every time.<br/> We are revolutionizing the industry through inspired people pushing the use of the latest technology for the purpose of reducing the life cycle costs of your ceiling and wall systems. 
        </p>
        </Box>
        </Container>
        </>
    )
}