import React from 'react';
import { Stack, Card, CardMedia, CardContent, Typography } from '@mui/material';
import image1 from './../assets/images/scope/scope_stack_image_1.jpg'
import image2 from './../assets/images/scope/scope_stack_image_2.jpg'
import { useNavigate } from 'react-router-dom';

const ScopeImageStack = () => {
    const navigate = useNavigate();
    const images = [
        {
            src: image1,
            title: 'Learn More',
            description: 'about this award-winning ceiling',
            route:"/portfolio_page/gulf-canada-square/"
        },
        {
            src: image2,
            title: 'Industry Leaders',
            description: 'in Specialized Ceiling & Acoustic System Installation',
            route:"/portfolio_page/national-music-centre/"
        },
    ];



    return (
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={5} justifyContent={'center'} alignItems={'center'}>
            {images.map((image, index) => (
                <Card className="scope_image_card" key={index} onClick={()=>{navigate(image.route)}}>
                    <CardMedia
                        component="img"
                        image={image.src}
                        alt={image.title}
                        className="media"
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}     
                    />
                    <CardContent className="content">
                        <Typography variant="h6" gutterBottom className='scope_image_title'>
                            {image.title}
                        </Typography>
                        <Typography variant="body2" className='scope_image_description'>{image.description}</Typography>
                        <Typography variant="body2" className='scope_image_description'>{image.description1}</Typography>
                    </CardContent>
                </Card>
            ))}
        </Stack>
    );
};

export default ScopeImageStack;