import React from "react";
import {
  CardMedia,
  Typography,
  Stack,
} from "@mui/material";
import Grid from '@mui/material/Grid2';
import "./../assets/css/ReletetdProject.css";
import { Link, useNavigate } from "react-router-dom";

const RelatedProjects = (props) => {

  let navigate = useNavigate();

  const onProjectClick = (link) => {
    if (!link) return null;
    navigate(link);
  };

  return (
    <>
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          textAlign: "center",
        }}
        className="related_project_title"
      >
        Related Projects
      </Typography>
      <Grid container paddingBottom={3} spacing={3.4}>
        {props?.projects?.map((project, index) => (
          <Grid size={{ xs: 12, sm: 12, md: 12, lg: 3, xl: 3 }} sx={{ margin: "0 0 2.5%" }}>
            <div className="image_holder">
              <CardMedia
                component="img"
                image={project.image}
                className="related_project_image"
              />
              <span className="text-holder">
                <span className="text-outer">
                  <span className="text-inner">
                    <span className="feature_holder_icons">
                      <Link className="view-text" to={project?.link}>view</Link>
                    </span>
                  </span>
                </span>
              </span>
            </div>
            <Stack style={{ padding: "20px 0 22px" }}>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                className="releted-project-sub-title"
                onClick={() => onProjectClick(project?.link ?? null)}
              >
                {project.title}
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                className="releted-project-sub-title-second">
                {project.firm}
              </Typography>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default RelatedProjects;

