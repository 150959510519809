import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/alberta/alberta_first.jpg";
import Img2 from "./../assets/images/alberta/alberta_second.jpg";
import Img3 from "./../assets/images/alberta/alberta_third.jpg";
import Img4 from "./../assets/images/alberta/alberta_four.jpg";
import Img5 from "./../assets/images/alberta/alberta_five.jpg";
import Img6 from "./../assets/images/alberta/alberta_six.jpg";
import Img7 from "./../assets/images/alberta/alberta_seven.jpg";
import projectImg from "./../assets/images/relatedprojects/harry.jpg";
import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import RelatedProjects from "../components/RelatedProjects";
import { Link } from "react-router-dom";


const Albertautilities = () => {
    const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6, Img7];

    const relatedDates = [
        {
            title: "Torys LLP",
            url: "/portfolio_page/torys/",
            date: "September 27, 2012"
        },
        {
            title: "Petrobakken Energy Ltd",
            url: "/portfolio_page/petrobakken/",
            date: "September 26, 2012"
        },
        {
            title: "Arcan Resources",
            url: "/portfolio_page/arcan-resources/",
            date: "October 23, 2012"
        }
    ]

    const projects = [
        {
            title: "Harry Hays – Parks Canada",
            firm: "Government",
            image: projectImg, // Placeholder image URL
            link: "/portfolio_page/harry-hays-parks-canada-2/", // URL path or external link
        },
    ];

    return (
        <>
            <div className="parent-div">
                <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Box>
                        <Contentimages
                            tab={"Calgary, Alberta"}
                            heading={"Alberta Utilities Commission"}
                            imageArray={imageArray}
                        >
                            <Link to={"http://www.auc.ab.ca/Pages/default.aspx"} target="_blank" className="navigation-link">Alberta Utilities Commission (AUC)</Link>{" "}recently relocated into the new Eau Claire Tower in downtown Calgary.
                            Designed by <Link to={"http://www.auc.ab.ca/Pages/default.aspx"} target="_blank" className="navigation-link">Kasian</Link>, AUC’s new offices on floors 12 and 14 are light and functional,
                            from the offices to the meeting rooms to the Hearing Room. Under contract to  <Link to={"https://govanbrown.com/"} target="_blank" className="navigation-link">Govan Brown,</Link>
                            {" "}MidWest completed drywall partitions, bulkheads and ceilings, installation of base building ceiling tile,
                            and supply and install of High Cloud perimeter trim. Plenum barriers were installed to increase sound absorption,
                            as well as Barymat in the wall cavities behind all electrical and data outlets.
                        </Contentimages>
                        <Grid2 container paddingBottom={10}></Grid2>
                        <PostRealetedDates relatedDates={relatedDates} />
                        <ProtfolioNavigation
                            backUrl="/portfolio_page/sheraton-eau-claire/"
                            nextUrl="/portfolio_page/esker-art-gallery-2/"
                        />
                        <RelatedProjects
                            projects={projects}
                        />
                    </Box>
                </Container>
            </div>
        </>


    );
};

export default Albertautilities;
