import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/certainteed/certainteed_first.jpg";
import Img2 from "./../assets/images/certainteed/certainteed_second.jpg";
import Img3 from "./../assets/images/certainteed/certainteed_third.jpg";
import Img4 from "./../assets/images/certainteed/certainteed_four.jpg";
import Img5 from "./../assets/images/certainteed/certainteed_five.jpg";
import Img6 from "./../assets/images/certainteed/certainteed_six.jpg";
import Img7 from "./../assets/images/certainteed/certainteed_seven.jpg";
import Contentimages from "../components/Contentimages";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import { Link } from "react-router-dom";


const Certainteed = () => {

    const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6, Img7];

    return (
        <>
            <div className="parent-div">
                <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Box>
                        <Contentimages
                            tab={"Calgary, Alberta"}
                            heading={"CertainTeed"}
                            imageArray={imageArray}
                        >

                            Under contract to <Link to={"https://www.epenterprisesinc.ca/"} target="_blank" className="navigation-link">E&P Enterprises</Link>
                            {" "}we recently completed the drywall and acoustical ceiling scope in an office renovation for building product  manufacturer <Link to={"https://www.certainteed.com/"} target="_blank" className="navigation-link">CertainTeed</Link>.
                            The project, designed by <Link to={"https://www.insite-design.ca/"} target="_blank" className="navigation-link">insite design group</Link>, is located on the lower level of their Calgary office; a modern mix of
                            open office space, airy meeting rooms, and a functional kitchen area. CertainTeed intends to use
                            this impressive new space as a showroom to demonstrate its unique interior products to customers.
                            Our pride and joy of the project is the elliptical bulkhead in one of the meeting rooms.
                            Painted CertainTeed’s signature blue and lined with linear lights, the bulkhead extends across the length of the room with a concave effect,
                            meeting the wall at the front of the room and creating a huge design impact.
                        </Contentimages>
                        <Grid2 container paddingBottom={10}></Grid2>
                        {/* <PostRealetedDates relatedDates={relatedDates} /> */}
                        <ProtfolioNavigation
                            backUrl="/portfolio_page/esker-art-gallery-2/"
                            nextUrl="/portfolio_page/meg-energy/"
                        />
                    </Box>
                </Container>
            </div>
        </>


    );
};

export default Certainteed;
