import { Box, Container, Grid2, Typography } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/fieldLow/field_first.jpg";
import Img2 from "./../assets/images/fieldLow/field_second.jpg";
import Img3 from "./../assets/images/fieldLow/field_third.jpg";
import Img4 from "./../assets/images/fieldLow/field_four.jpg";
import Img5 from "./../assets/images/fieldLow/field_five.jpg";
import Img6 from "./../assets/images/fieldLow/field_six.jpg";
import Img7 from "./../assets/images/fieldLow/field_seven.jpg";
import Img8 from "./../assets/images/fieldLow/field_eight.jpg";
import Img9 from "./../assets/images/fieldLow/field_nine.jpg";

import Project_first from "./../assets/images/relatedprojects/gowling.jpg";
import Project_second from "./../assets/images/relatedprojects/torys.jpg";
import Project_three from "./../assets/images/relatedprojects/heenan.jpg";
import Project_four from "./../assets/images/relatedprojects/burnet.jpg";

import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import RelatedProjects from "../components/RelatedProjects";


const FieldLaw = () => {
  const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9];

  const relatedDates = [
    {
      title: "Esker Art Gallery",
      url: "/portfolio_page/esker-art-gallery-2/",
      date: "March 24, 2018",
    },
    {
      title: "Burnet, Duckworth & Palmer LLP",
      url: "/portfolio_page/burnet-duckworth-palmer/",
      date: "September 24, 2012",
    },
    {
      title: "TransCanada Interior Renovation",
      url: "/portfolio_page/transcanada-interior-renovation/",
      date: "April 10, 2019",
    },
  ];

  const projects = [
    {
      title: "Gowling wlg",
      firm: "Law",
      image: Project_first,
      link: "/portfolio_page/gowling/",
    },
    {
      title: "Torys llp",
      firm: "Law",
      image: Project_second,

      link: "/portfolio_page/torys/",
    },
    {
      title: "Heenan Blaikie Llp",
      firm: "Law",
      image: Project_three,
      link: "/portfolio_page/heenan-blaikie/",
    },
    {
      title: "Burnet, Duckworth & Palmer llp",
      firm: "Law",
      image: Project_four,
      link: "/portfolio_page/burnet-duckworth-palmer/",
    },
  ];

  return (
    <>
      <div className="parent-div">
        <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Box>
            <Contentimages
              tab={"Calgary, Alberta"}
              heading={"Field Law"}
              imageArray={imageArray}>

              Field Law’s new office space, located in Barclay Centre, was
              designed by Sizeland Evans and combines rich history with
              contemporary design. MidWest Group’s scope of work included
              drywall partitions and finishings, drywall ceilings including a
              sloped ceiling, bulkheads and reveals, installation of ceiling
              tile and additional taping to accommodate linear flangeless
              lights.
              <blockquote>
                <Typography
                  variant="em"
                  className="sub-content-fieldLow"
                  sx={{
                    fontStyle: "italic",
                    fontFamily: "Open Sans",
                    fontSize: "14px",
                    lineHeight: "27px",
                    fontWeight: "400px",
                    color: "#5f8c27",
                  }}>
                  <span style={{ fontSize: "36pt" }}>"</span>
                  The workmanship for all things drywall and acoustical ceilings
                  has been exemplary, and thanks to [MidWest’s] flexibility and
                  availability when changes ‘on the fly’ had to be implemented,
                  the project got done on time and to the full satisfaction of
                  all parties involved.”
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mt: 2,
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}>
                  – Andy Voramwald, CEO of Cougar Contractors Ltd.
                </Typography>
              </blockquote>
            </Contentimages>

            <Grid2 container paddingBottom={10}></Grid2>
            <PostRealetedDates relatedDates={relatedDates} />
            <ProtfolioNavigation
              backUrl="/portfolio_page/cisco/"
              nextUrl="/portfolio_page/simons/"
            />
            <RelatedProjects projects={projects} />
          </Box>
        </Container>
        
      </div>
    </>
  );
};

export default FieldLaw;
