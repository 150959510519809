import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/pembina/pembina_first.jpg";
import Img2 from "./../assets/images/pembina/pembina_second.jpg";
import Img3 from "./../assets/images/pembina/pembina_third.jpg";
import Img4 from "./../assets/images/pembina/pembina_four.jpg";
import Img5 from "./../assets/images/pembina/pembina_five.jpg";
import Img6 from "./../assets/images/pembina/pembina_six.jpg";
import Img7 from "./../assets/images/pembina/pembina_seven.jpg";
import Img8 from "./../assets/images/pembina/pembina_eight.jpg";
import Img9 from "./../assets/images/pembina/pembina_nine.jpg";
import Project_first from "../assets/images/projectsImages/IMG_6229_edited.jpg";
import Project_second from "../assets/images/projectsImages/1.jpg";
import Project_third from "../assets/images/relatedprojects/cenovus.jpg";
import Project_four from "../assets/images/relatedprojects/bow.jpg"
import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import RelatedProjects from "../components/RelatedProjects";
import { Link } from "react-router-dom";


const Pembinapipline = () => {

    const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9];

    const relatedDates = [
        {
            title: "MEG Energy",
            url: "/portfolio_page/meg-energy/",
            date: "May 18, 2017",
        },
        {
            title: "Field Law Calgary",
            url: "/portfolio_page/field-law/",
            date: "April 8, 2017",
        },
        {
            title: "Imperial Oil – Quarry Park",
            url: "/portfolio_page/imperial-oil-quarry-park/",
            date: "February 25, 2016",
        },
    ];

    const projects = [
        {
            title: "MEG Energy",
            firm: "Oil & Gas",
            image: Project_first
            , // Placeholder image URL
            link: "/portfolio_page/meg-energy/", // URL path or external link
        },
        {
            title: "Imperial Oil – Quarry Park",
            firm: "Oil & Gas",
            image: Project_second,
            link: "/portfolio_page/imperial-oil-quarry-park/",
        },
        {
            title: "Cenovus Energy",
            firm: "Oil & Gas",
            image: Project_third,
            link: "/portfolio_page/cenovus-energy/",
        },
        {
            title: "Bow Tower",
            firm: "Oil & Gas",
            image: Project_four,
            link: "/portfolio_page/bow-tower/",
        },
    ];

    return (
        <>
            <div className="parent-div">
                <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Box>
                        <Contentimages
                            tab={"Calgary, Alberta"}
                            heading={"Pembina"}
                            imageArray={imageArray}
                        >
                            Under contract to <Link to={"https://www.lignuminteriors.com/"} target="_blank" className="navigation-link">Lignum Interiors,</Link> the MidWest scope consisted of 14 floors, grid, tile, framing, and coordination of bulkheads. The Sizeland Evans team designed unique minimalist features for the Pembina Pipeline corporate office space. Intricate bulkheads can be spotted throughout the space, in addition to 5,000 square feet of ceiling tile and T-bar with no visible deviations.
                            <br></br>
                            <br></br>
                            The most notable feature is <Link target="_blank" to={"https://www.armstrongceilings.com/commercial/en/commercial-ceilings-walls/woodworks-grille-classics-ceiling-tiles.html"} className="navigation-link">Armstrong’s WoodWorks Grille</Link>{" "}ceilings which is used to accent the beautiful bulkheads and angled walls.
                        </Contentimages>
                        <Grid2 container paddingBottom={10}></Grid2>
                        <PostRealetedDates relatedDates={relatedDates} />
                        <ProtfolioNavigation
                            backUrl="/portfolio_page/gowling/"
                            nextUrl="/portfolio_page/cenovus-energy/"
                        />
                        <RelatedProjects
                            projects={projects}
                        />
                    </Box>
                </Container>
            </div>
        </>


    );
};

export default Pembinapipline;
