import { Box, Container, Grid2, Typography } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/simons/simons_first.jpg";
import Img2 from "./../assets/images/simons/simons_second.jpg";
import Img3 from "./../assets/images/simons/simons_third.jpg";
import Img4 from "./../assets/images/simons/simons_four.jpg";
import Img5 from "./../assets/images/simons/simons_five.jpg";
import Img6 from "./../assets/images/simons/simons_six.jpg";
import Img7 from "./../assets/images/simons/simons_seven.jpg";
import Img8 from "./../assets/images/simons/simons_eight.jpg";
import Img9 from "./../assets/images/simons/simons_nine.jpg";

import Project_first from "../assets/images/relatedprojects/ashley_furniture.jpg"
import Project_second from "../assets/images/relatedprojects/sage_hill.png"
import Project_third from "../assets/images/relatedprojects/gulf_canada.jpg"
import Project_four from "../assets/images/relatedprojects/nordstrom.jpg"

import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import RelatedProjects from "../components/RelatedProjects";


const Simons = () => {
  const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9];

  const relatedDates = [
    {
      title: "Nordstrom Chinook",
      url: "/portfolio_page/nordstrom/",
      date: "March 25, 2016",
    },
    {
      title: "Gulf Canada Square Food Court",
      url: "/portfolio_page/gulf-canada-square/",
      date: "September 25, 2016",
    },
    {
      title: "Sage Hill Quarter",
      url: "/portfolio_page/sage-hill-quarter/",
      date: "August 29, 2018",
    },
  ];

  const projects = [
    {
      title: "Ashley Furniture HomeStore",
      firm: "Retail",
      image: Project_first,
      link: "/portfolio_page/ashley-furniture-homestore/",
    },
    {
      title: "Sage Hill Quarter",
      firm: "Retail",
      image: Project_second,
      link: "/portfolio_page/sage-hill-quarter/",
    },
    {
      title: "Gulf Canada Square Food Court",
      firm: "Retail",
      image: Project_third,
      link: "/portfolio_page/gulf-canada-square/",
    },
    {
      title: "Nordstrom Chinook",
      firm: "Retail",
      image: Project_four,
      link: "/portfolio_page/nordstrom/",
    },
  ];

  const onUrlClick = (url) => {
    window.open(url, '_blank');
  }

  return (
    <>
      <div className="parent-div">
        <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Box>
            <Contentimages
              tab={"Calgary, Alberta"}
              heading={"Simons"}
              imageArray={imageArray}>
              Simons is occupying four levels for retail and the foundation
              level for logistics, administration and retail floor employees.
              With over 90,000 square feet of retail space, MidWest’s scope
              entails framing 150,000 linear feet of CFS and over 300,000 square
              feet of drywall to enclose the interior structure. Local
              architecture & interior design firm,
              <span className="portfolio_url_text"
                onClick={() => {
                  onUrlClick("https://www.mckinleyburkart.com/lander")
                }}
              > McKinley Burkart </span>,
              focused on highlighting the roots of the heritage building by
              incorporating its architecture while adding innovative and modern
              pieces.
              <blockquote>
                <Typography
                  variant="em"
                  className="sub-content-fieldLow"
                  sx={{
                    fontStyle: "italic",
                    fontFamily: "Open Sans",
                    fontSize: "14px",
                    lineHeight: "27px",
                    fontWeight: "400px",
                    color: "#5f8c27",
                  }}>
                  <span style={{ fontSize: "36pt" }}>"</span>
                  Govan Brown’s Safety Department is pleased to have worked with
                  MidWest Group on the Simons job in the Calgary Downtown CORE.
                  This job was very intricate in a lot of areas as the design
                  was one that will speak for itself as you look around the
                  space and observe areas of fine design and innovative
                  architectural design. Govan Brown’s Safety Manager Ian
                  Campbell is very pleased to have worked with a group of trade
                  workers that took the time to ensure safety was a top level of
                  concern while on the job. Rob Dunlop was very instrumental in
                  ensuring the job was completed in a timely manner and all
                  areas of safety were addressed when needed. Jeff Williams
                  should be proud of his team and all workers that completed
                  this project with “0” lost time incidents.”
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mt: 2,
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}>
                  – Ian Campbell, Health & Safety Manager at Govan Brown
                </Typography>
              </blockquote>
            </Contentimages>

            <Grid2 container paddingBottom={10}></Grid2>
            <PostRealetedDates relatedDates={relatedDates} />
            <ProtfolioNavigation
              backUrl="/portfolio_page/field-law/"
              nextUrl="/portfolio_page/national-music-centre/"
            />
            <RelatedProjects projects={projects} />
          </Box>
        </Container>
        
      </div>
    </>
  );
};

export default Simons;
