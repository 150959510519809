import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/bow/bow_first.jpg";
import Img2 from "./../assets/images/bow/bow_second.jpg";
import Img3 from "./../assets/images/bow/bow_third.jpg";
import Img4 from "./../assets/images/bow/bow_four.jpg";
import Img5 from "./../assets/images/bow/bow_five.jpg";
import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import RelatedProjects from "../components/RelatedProjects";

import Project_first from "./../assets/images/projectsImages/IMG_6229_edited.jpg";
import Project_second from "./../assets/images/projectsImages/1.jpg";


import Project_third from "../assets/images/relatedprojects/pembina.jpg";
import Project_four from "../assets/images/relatedprojects/cenovus.jpg";

import { Link } from "react-router-dom";

const Bowtower = () => {
  const imageArray = [Img1, Img2, Img3, Img4, Img5];

  const relatedDates = [
    {
      title: "Gowling WLG",
      url: "/portfolio_page/gowling/",
      date: "September 26, 2015",
    },
    {
      title: "MEG Energy",
      url: "/portfolio_page/meg-energy/",
      date: "May 18, 2017",
    },
    {
      title: "Gulf Canada Square Food Court",
      url: "/portfolio_page/gulf-canada-square/",
      date: "September 25, 2016",
    },
  ];

  const projects = [
    {
      title: "MEG Energy",
      firm: "Oil & Gas",
      image: Project_first
      , // Placeholder image URL
      link: "/portfolio_page/meg-energy/", // URL path or external link
    },
    {
      title: "Imperial Oil – Quarry Park",
      firm: "Oil & Gas",
      image: Project_second,
      link: "/portfolio_page/imperial-oil-quarry-park/",
    },
    {
      title: "Pembina Pipeline",
      firm: "Oil & Gas",
      image: Project_third,
      link: "/portfolio_page/pembina/",
    },
    {
      title: "Cenovus Energy",
      firm: "Oil & Gas",
      image: Project_four,
      link: "/portfolio_page/cenovus-energy/",
    },
  ];


  return (
    <>
      <div className="parent-div">
        <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Box>
            <Contentimages
              tab={"Calgary, Alberta"}
              heading={"Bow Tower"}
              imageArray={imageArray}
            >
              The Bow Tower is an iconic piece of Calgary’s skyline, standing 58 storeys high. Located on the 54th and 55th floors, two multi-purpose rooms feature wood-panelled walls designed for acoustic performance.
              <br></br>
              <br></br>
              Under contract to <Link to={"https://www.cambiumwoodwork.com/"} target="_blank" className="navigation-link">Cambium Woodwork,</Link> MidWest Group worked closely with the other trades in the design and construction of these high achieving walls. Stretching over 20’ tall, the framework behind the wall had to withstand the weight of the panels and deliver a sloped appearance, resulting in a complex labyrinth of HSS, steel stud, plywood sheathing, and cleats.
            </Contentimages>
            <Grid2 container paddingBottom={10}></Grid2>
            <PostRealetedDates relatedDates={relatedDates} />
            <ProtfolioNavigation
              backUrl="/portfolio_page/cenovus-energy/"
              nextUrl="/portfolio_page/nexen-wellness/"
            />
            <RelatedProjects
              projects={projects}
            />
          </Box>
        </Container>
      </div>
    </>


  );
};

export default Bowtower;
