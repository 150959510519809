import Grid from "@mui/material/Grid2";
import "../assets/css/Footer.css";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Stack } from "@mui/material";

export default function Footer() {
  return (
    <div class="footer_top_holder">
      <div class="footer_top">
        <div class="container">
          <div class="container_inner">
            <Grid container spacing={4}>
              <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                <div className="column1 footer_col1">
                  <div className="column_inner">
                    <div className="widget widget_text">
                      <h5>Contact</h5>{" "}
                      <div className="textwidget">
                        <p>
                          info@midwest1988.com
                          <br />
                          403-277-1551
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </Grid>
              <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                <div className="column1 footer_col1">
                  <div className="column_inner">
                    <div id="text-12" class="widget widget_text">
                      <h5>HEAD OFFICE</h5>{" "}
                      <div className="textwidget">
                        <p>
                          261185 Wagon Wheel Way
                          <br />
                          Rocky View, AB T4A 0E2
                        </p>
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </Grid>

              <Grid size={{ xs: 12, sm: 4, md: 4 }}>
                <div className="column1 footer_col1">
                  <div className="column_inner">
                    <div id="text-12" class="widget widget_text">
                      <h5>Follow us on social</h5>{" "}
                      <Stack className="footericons" gap={2} flexDirection={"row"} justifyContent={"center"}>
                        <a href="https://www.linkedin.com/company/3624629" target="_blank" rel="noopener noreferrer">
                          <LinkedInIcon fontSize={"large"} />
                        </a>
                        <a href="https://twitter.com/Midwest_Group" target="_blank" rel="noopener noreferrer">
                          <TwitterIcon fontSize={"large"} />
                        </a>
                      </Stack>
                      <div className="textwidget">
                        <p style={{ marginBlock: "0", lineHeight: "10px" }}>
                          <span style={{ fontSize: "7pt" }}>
                            © MidWest Group. 2024 All rights reserved.
                          </span>
                        </p>
                      </div>

                    </div>{" "}
                  </div>
                </div>
              </Grid>
            </Grid>

          </div>
        </div>
      </div>
    </div>
  );
}
