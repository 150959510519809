import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import RelatedProjects from "../components/RelatedProjects";

import Img1 from "./../assets/images/portfolio_pagemeg-energy/1.jpg";
import Img2 from "./../assets/images/portfolio_pagemeg-energy/2.jpg";
import Img3 from "./../assets/images/portfolio_pagemeg-energy/3.jpg";
import Img4 from "./../assets/images/portfolio_pagemeg-energy/4.jpg";

import Projectfirst from "./../assets/images/projectsImages/IMG_6229_edited.jpg";
import Projectsecond from "./../assets/images/projectsImages/1.jpg";
import Projectthree from "./../assets/images/relatedprojects/pembina.jpg";
import Projectfour from "./../assets/images/relatedprojects/cenovus.jpg";


const PetrobakkenEnergy = () => {
  const imageArray = [Img1, Img2, Img3, Img4];

  const relatedDates = [
    {
      title: "Field Law Calgary",
      url: "/portfolio_page/field-law/",
      date: "April 8, 2017",
    },
    {
      title: "Burnet, Duckworth & Palmer LLP",
      url: "/portfolio_page/burnet-duckworth-palmer/",
      date: "September 24, 2012",
    },
    {
      title: "MEG Energy",
      url: "/portfolio_page/meg-energy/",
      date: "May 18, 2017",
    },
  ];

  const projects = [
    {
      title: "MEG Energy",
      firm: "Oil & Gas",
      image: Projectfirst,
      link: "/portfolio_page/meg-energy/",
    },
    {
      title: "Imperial Oil – Quarry Park",
      firm: "Oil & Gas",
      image: Projectsecond,
      link: "/portfolio_page/bow-tower/",
    },
    {
      title: "Pembina Pipeline",
      firm: "Oil & Gas",
      image: Projectthree,
      link: "/portfolio_page/pembina/",
    },
    {
      title: "Cenovus Energy",
      firm: "Oil & Gas",
      image: Projectfour,
      link: "/portfolio_page/cenovus-energy/",
    },
  ];

  return (
    <>
      <div className="parent-div">
        <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Box>
            <Contentimages
              tab={"Calgary, Alberta"}
              heading={"Petrobakken Energy Ltd"}
              imageArray={imageArray}></Contentimages>

            <Grid2 container paddingBottom={10}></Grid2>
            <PostRealetedDates relatedDates={relatedDates} />
            <ProtfolioNavigation
              backUrl="/portfolio_page/torys/"
              nextUrl="/portfolio_page/heenan-blaikie/"
            />
            <RelatedProjects projects={projects} />
          </Box>
        </Container>
        
      </div>
    </>
  );
};

export default PetrobakkenEnergy;
