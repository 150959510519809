import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";

import Img1 from "./../assets/images/DevonFitness/1.jpg";
import Img2 from "./../assets/images/DevonFitness/2.jpg";
import Img3 from "./../assets/images/DevonFitness/3.jpg";
import Img4 from "./../assets/images/DevonFitness/4.jpg";
import Img5 from "./../assets/images/DevonFitness/5.jpg";
import Img6 from "./../assets/images/DevonFitness/6.jpg";
import Img7 from "./../assets/images/DevonFitness/7.jpg";
import Img8 from "./../assets/images/DevonFitness/8.jpg";
import Img9 from "./../assets/images/DevonFitness/9.jpg";

function DevonFitness() {

    const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9];


    const relatedDates = [
        {
            title: "National Music Centre",
            url: "/portfolio_page/national-music-centre/",
            date: "January 21, 2017"
        },
        {
            title: "Burnet, Duckworth & Palmer LLP",
            url: "/portfolio_page/burnet-duckworth-palmer/",
            date: "September 24, 2012"
        },
        {
            title: "Field Law Calgary",
            url: "/portfolio_page/field-law/",
            date: "April 8, 2017"
        }
    ]

    const onUrlClick = (url) => {
        window.open(url, '_blank');
    }

    return (
        <>
            <div className="parent-div">
                <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Box>
                        <Contentimages
                            heading={"Devon 3 Fitness Centre"}
                            tab={"Calgary, Alberta"}
                            imageArray={imageArray}
                        >
                            In conjunction with {" "}
                            <span className="portfolio_url_text"
                                onClick={() => {
                                    onUrlClick("http://www.cougar-contractors.com/")
                                }}
                            >Cougar Contractors</span> {"  "}
                            and
                            {" "}  <span className="portfolio_url_text"
                                onClick={() => {
                                    onUrlClick("https://kasian.com/")
                                }}
                            >Kasian Architecture</span>,
                            MidWest Group recently completed the drywall contracting scope for Devon Tower’s contemporary new
                            <span className="portfolio_url_text"
                                onClick={() => {
                                    onUrlClick("https://400thirdfitness.ca/")
                                }}
                            >{" "}Fitness Centre</span>.
                            <br />
                            The 6,000 sq.ft. facility includes a reception area, locker rooms, group fitness room, stretching area, and a well-stocked zone that hosts state-of-the-art cardio and weight equipment.
                            <br />
                            Close attention to detail by the designer is noticeable throughout the Fitness Centre, such as carefully planned transitions between wall coverings and other high-end finishes.
                            Achieving the overall look and feel desired by the owners required prudent quality control during construction,
                            specifically the highly detailed taping finishes.
                            <br />
                            The Fitness Centre highlights the commitment of the building owner,
                            <span className="portfolio_url_text"
                                onClick={() => {
                                    onUrlClick("https://www.oxfordproperties.com/")
                                }}
                            > Oxford Properties</span>,
                            to workplace health and wellness. The finished and now open facility is sure to attract and inspire members for many years to come.
                        </Contentimages>
                        <Grid2 container paddingBottom={10}></Grid2>
                        <PostRealetedDates relatedDates={relatedDates} />
                        <ProtfolioNavigation
                            backUrl="/portfolio_page/transcanada-interior-renovation/"
                            nextUrl="/portfolio_page/ashley-furniture-homestore/"
                        />
                    </Box>
                </Container>
            </div>
        </>
    )
}

export default DevonFitness 