import { Box, Container, Grid2, Typography } from "@mui/material";
import React from "react";
import Img1 from "./../assets/images/burnetDuckw/1.jpg";
import Img2 from "./../assets/images/burnetDuckw/2.jpg";
import Img3 from "./../assets/images/burnetDuckw/3.jpg";
import Img4 from "./../assets/images/burnetDuckw/4.jpg";
import Img5 from "./../assets/images/burnetDuckw/5.jpg";
import Img6 from "./../assets/images/burnetDuckw/6.jpg";
import Project_first from "./../assets/images/relatedprojects/field_law.jpg";
import Project_second from "./../assets/images/relatedprojects/gowling.jpg";
import Project_three from "./../assets/images/relatedprojects/torys.jpg";
import Project_four from "./../assets/images/relatedprojects/heenan.jpg";
import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import RelatedProjects from "../components/RelatedProjects";


const BurnetDuckworth = () => {
  const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6];

  const relatedDates = [
    {
      title: "Field Law Calgary",
      url: "/portfolio_page/field-law/",
      date: "April 8, 2017",
    },
    {
      title: "Ashley Furniture HomeStore",
      url: "/portfolio_page/ashley-furniture-homestore/",
      date: "October 4, 2018",
    },
    {
      title: "MEG Energy",
      url: "/portfolio_page/meg-energy/",
      date: "May 18, 2017",
    },
  ];

  const projects = [
    {
      title: "Field Law Calgary",
      firm: "Law",
      image: Project_first,
      link: "/portfolio_page/field-law/",
    },
    {
      title: "Gowling wlg",
      firm: "Law",
      image: Project_second,
      link: "/portfolio_page/gowling/",
    },
    {
      title: "Torys llp",
      firm: "Law",
      image: Project_three,
      link: "/portfolio_page/torys/",
    },
    {
      title: "Heenan Blaikie Llp",
      firm: "Law",
      image: Project_four,
      link: "/portfolio_page/heenan-blaikie/",
    },
  ];

  return (
    <>
      <div className="parent-div">
        <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <Box>
            <Contentimages
              tab={"Calgary, Alberta"}
              heading={"Burnet, Duckworth & Palmer LLP"}
              imageArray={imageArray}>
              Alongside general contractor Lignum Interiors, MidWest Group
              completed interior partitions and ceilings for Burnet, Duckworth &
              Palmer LLP’s six floors in Eighth Avenue Place. Our scope entailed
              typical drywall partitions, plenum barriers including drywall,
              rigid insulation, and security mesh, partial height walls with HSS
              posts, supply and installation of extruded aluminum closures,
              drywall ceilings, and installation of suspended ceilings. It was
              important that the structure and taping finish were flawless.
              Close attention to detail was paramount to enhance the first-rate
              design by Martens Group, an inviting environment that complements
              the corporate culture and goals of the law firm.
              <blockquote>
                <Typography
                  variant="em"
                  className="sub-content-fieldLow"
                  sx={{
                    fontStyle: "italic",
                    fontFamily: "Open Sans",
                    fontSize: "14px",
                    lineHeight: "27px",
                    fontWeight: "400px",
                    color: "#5f8c27",
                  }}>
                  <span style={{ fontSize: "36pt" }}>"</span>
                  We would like to take this opportunity to convey our pleasure
                  in working with you and everyone associated with MidWest Group
                  on the recent Burnet, Duckworth & Palmer LLP project, as well
                  as various past projects, in Calgary. Martens Group strives to
                  deliver creative interior design solutions that support and
                  enhance our client’s strategic goals, represent their identity
                  and image reinforcing their business principles and values. To
                  accomplish this goal, we rely on highly qualified
                  subcontractors like MidWest Group. Your attention to detail
                  and working together as a team member is commendable.”
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mt: 2,
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}>
                  – Interior Design Studio
                </Typography>
              </blockquote>
            </Contentimages>

            <Grid2 container paddingBottom={10}></Grid2>
            <PostRealetedDates relatedDates={relatedDates} />
            <ProtfolioNavigation backUrl="/portfolio_page/heenan-blaikie/" />
            <RelatedProjects projects={projects} />
          </Box>
        </Container>
        
      </div>
    </>
  );
};

export default BurnetDuckworth;
