import { Box, Container, Grid2 } from "@mui/material";
import React from "react";
import Contentimages from "../components/Contentimages";
import PostRealetedDates from "../components/PostRealetdDates";
import ProtfolioNavigation from "../components/ProtfolioNavigation";
import Img1 from "./../assets/images/HarryHays/1.jpg";
import Img2 from "./../assets/images/HarryHays/2.jpg";
import Img3 from "./../assets/images/HarryHays/3.jpg";
import Img4 from "./../assets/images/HarryHays/4.jpg";
import Img5 from "./../assets/images/HarryHays/5.jpg";
import Img6 from "./../assets/images/HarryHays/6.jpg";
import Img7 from "./../assets/images/HarryHays/7.jpg";
import Img8 from "./../assets/images/HarryHays/8.jpg";
import Img9 from "./../assets/images/HarryHays/9.jpg";
import ProjectImg1 from "./../assets/images/relatedprojects/alberta.jpg";
import RelatedProjects from "../components/RelatedProjects";



const HarryHays = () => {

    const imageArray = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8, Img9];


    const relatedDates = [
        {
            title: "Gulf Canada Square Food Court",
            url: "/portfolio_page/gulf-canada-square/",
            date: "September 25, 2016"
        },
        {
            title: "Gowling WLG",
            url: "/portfolio_page/gowling/",
            date: "September 26, 2015"
        },
        {
            title: "Burnet, Duckworth & Palmer LLP",
            url: "/portfolio_page/burnet-duckworth-palmer/",
            date: "September 24, 2012"
        }
    ]


    const projects = [
        {
            title: "Alberta Utilities Commission",
            firm: "Government",
            image: ProjectImg1,
            link: "/portfolio_page/alberta-utilities-commission/",
        }
    ];

    const onUrlClick = (url) => {
        window.open(url, '_blank');
    }

    return (
        <>
            <div className="parent-div">
                <Container fixed className="parent-container" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Box>
                        <Contentimages
                            heading={"Harry Hays – Parks Canada"}
                            tab={"Calgary, Alberta"}
                            imageArray={imageArray}
                        >

                            Under contract to
                            <span className="portfolio_url_text"
                                onClick={() => {
                                    onUrlClick("https://govanbrown.com/")
                                }}
                            > Govan Brown</span>

                            , MidWest Group completed the drywall
                            contracting scope of the new Parks Canada office in preparation of
                            their relocation within Calgary’s Harry Hays building.

                            <br />

                            <span className="portfolio_url_text"
                                onClick={() => {
                                    onUrlClick("https://ridi.ca/")
                                }}
                            > Designer Rodych Integrated Design Inc</span>

                            . incorporated innovative
                            designs into the space, including a kitchenette wall that displays
                            a series of shapes layered onto the wall. MidWest framed this wall
                            with triangular openings, and


                            <span className="portfolio_url_text"
                                onClick={() => {
                                    onUrlClick("https://perfectionmillwork.com/commercial-millwork/")
                                }}
                            > Perfection Millwork </span>
                            installed layers of angular millwork over top. The wall was then finished with bright blue, green, and white paint to achieve the complex design appearance.

                            <br />
                            Other features of the project include a level five finish to
                            accommodate mural walls, and installation of vinyl product in
                            high-traffic areas to offer surface protection.

                        </Contentimages>
                        <Grid2 container paddingBottom={5}></Grid2>
                        <PostRealetedDates relatedDates={relatedDates} />
                        <ProtfolioNavigation
                            backUrl="/portfolio_page/sage-hill-quarter/"
                            nextUrl="/portfolio_page/sheraton-eau-claire/"
                        />

                        <RelatedProjects
                            projects={projects}
                        />

                    </Box>
                </Container>
            </div>
        </>
    )
}

export default HarryHays