import { Box, Button, Card, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "../assets/css/Home.css";
import Grid from "@mui/material/Grid2";
import havePlace from "../assets/images/midwest-8thaveplace.png";
import gulfCanada from "../assets/images/midwest-gulfcanada.png";
import scaled from "../assets/images/scaled.png";
import scaled2 from "../assets/images/scaled2.png";
import safetyCulture from "../assets/images/safety-culture.png";
import homeVideo from "../assets/video/homeVideo.mp4";


export default function Home() {
  const navigate = useNavigate();

  return (
    <>
      <div className="landing-container">
        <video className="background-video" autoPlay loop muted>
          <source src={homeVideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="centered-text-home">
          <h1 className="slide-in-text" style={{ fontWeight: "400" }}>
            We are MIDWEST, part of the</h1>
           <h1 className="slide-in-text" style={{ fontWeight: "400" }}>THERMAL SYSTEMS group of companies</h1>
        </div>
      </div>

      <div className="meet-section">
        <h2 style={{ textAlign: "center" }}>– MEET MIDWEST –</h2>
        {/* <div > */}
          <Button variant="contained" className="about-button" onClick={() => navigate("/about")}>
            About
          </Button>
        {/* </div> */}

        <Box p={2} mt={4}>
          <Grid container spacing={4}>
            <Grid item size={{ md: 3, xs: 12, sm: 3 }}>
              <Card>
                <CardMedia
                  component="img"
                  height="250"
                  width="100%"
                  image={havePlace}
                  alt="MidWest office"
                  sx={{
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "scale(1.05) translateZ(0)",
                    },
                    cursor:"pointer"
                  }}
                  onClick={() => navigate("portfolio_page/pembina/")}
                />
              </Card>
            </Grid>

            <Grid item size={{ md: 6, xs: 12, sm: 6 }} sx={{ display: "flex", alignItems: "center" }}>
              <div className="meet-paragraph">
                <p>
                  Here at MidWest Group, we treat each other like family. The
                  talent our drywall and ceiling specialists bring to work every
                  day is highly valuable. Our craftsmen bring over 30 years of
                  experience to the job site and have an unparalleled passion
                  for what they do. It’s a unique combination of abilities that
                  are exclusive to MidWest.
                  <br />
                  We have artisans who love what they do and excel at creating
                  top-quality products. Our clients know this and will use the
                  services we provide without hesitation.
                </p>
              </div>
            </Grid>
            <Grid item size={{ md: 3, xs: 12, sm: 3 }}>
              <Card>
                <CardMedia
                  component="img"
                  height="250"
                  width="100%"
                  image={gulfCanada}
                  alt="MidWest office"
                  sx={{
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "scale(1.05) translateZ(0)",
                    },
                    cursor:"pointer"
                  }}
                  onClick={() => navigate("portfolio_page/gulf-canada-square/")}
                />
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Grid container spacing={4} paddingTop={5}>
          {/* Left Section: Image */}
          <Grid item size={{ md: 8, xs: 12, sm: 8 }}>
            <Card>
              <CardMedia
                component="img"
                height="100%"
                width="100%"
                image={scaled}
                alt="MidWest office"
                sx={{
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05) translateZ(0)",
                  },
                }}
                onClick={() => navigate("/portfolio_page/transcanada-interior-renovation/")}
              />
            </Card>
          </Grid>

          <Grid item size={{ md: 4, xs: 12, sm: 4 }}>
            <Card>
              <CardMedia
                component="img"
                height="100%"
                width="100%"
                image={scaled2}
                alt="MidWest office"
                sx={{
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05) translateZ(0)",
                  },
                }}
                onClick={() => navigate("/portfolio_page/national-music-centre/")}
              />
            </Card>
          </Grid>
        </Grid>

        <Grid container mt={4} mb={4}>
          <Grid item size={{ md: 4, xs: 12, sm: 4 }}></Grid>
          <Grid item size={{ md: 4, xs: 12, sm: 4 }}>
            <Card sx={{ boxShadow: "none" }}>
              <CardMedia
                component="img"
                image={safetyCulture}
                alt="safetyCulture"
                sx={{
                  borderRadius: "50%",
                  transition: "opacity 0.3s ease-in-out", // Smooth transition effect
                  opacity: 1, // Default opacity
                  "&:hover": {
                    opacity: 0.7, // Opacity on hover
                  },
                  cursor:"pointer"
                }}
                onClick={() => navigate("/safety/")}
              />
            </Card>
          </Grid>
          <Grid item size={{ md: 4, xs: 12, sm: 4 }}></Grid>
        </Grid>
      </div>
    </>
  );
}
