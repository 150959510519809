import React from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import AppsIcon from '@mui/icons-material/Apps';
import { useNavigate } from 'react-router-dom';

const ProtfolioNavigation = (props) => {

    let navigate = useNavigate();

    const onArrowClick = (type) => {
        if (type === "back") {
            navigate(props?.backUrl);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }

        if (type === "next") {
            navigate(props?.nextUrl);
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }

        if (type === "projects") {
            navigate("/projects/");

        }

        return null;
    }

    return (
        <>
            <div style={{ marginTop: '50px'}}>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ textAlign: 'left' }}>
                        {
                            props?.backUrl && (
                                <div className='protfolio_btn' onClick={() => { onArrowClick("back") }}>
                                    <ArrowBackIosIcon fontSize='15px' style={{ marginLeft: 'auto', marginRight: '10px' }} />
                                </div>
                            )
                        }
                    </div>
                    <div style={{ textAlign: 'center' }}>
                        <div
                            className='protfolio_app_icon_navigation'
                        >
                            <AppsIcon onClick={() => { onArrowClick("projects") }} fontSize='200px' color='red' />
                        </div>
                    </div>
                    <div style={{ textAlign: 'right' }}>
                        {
                            props?.nextUrl && (
                                <div className='protfolio_btn' onClick={() => { onArrowClick("next") }}>
                                    <ArrowForwardIosIcon fontSize='15px' style={{ marginLeft: 'auto', marginRight: 'auto' }} />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProtfolioNavigation