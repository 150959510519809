import { Box, Stack, Container } from "@mui/material";
import React from "react";
import "../assets/css/Safety.css";
import Safetyhats from "../assets/images/safety-hats.png";

export default function Safety(props) {

    return (
        <Box className="safety-content">
            <Container className="safety-container">
                <Stack className="safety-header" textAlign={"center"}>
                    <h3 className="safety-heading">Safety Culture</h3>
                    <div className="safety-info">
                        <p className="info">
                            In order to achieve our goal of being the best subcontractor on site every time, a solid culture of safety must be in place. Being a part of the Thermal Systems group of companies means having a structured and successful HSE Program that allows us to perform safely and productively on all projects. Our success as an organization is based on our people going home every day without injury.
                        </p>
                    </div>
                    <div className="safety-info">
                        <p className="info">
                            Our people are our greatest asset and providing them with a safe working environment is a top priority. We are committed to providing every employee with the required training, equipment and supporting Health and Safety Program that allows our team members to know they’re competent to complete the task at hand.
                        </p>
                    </div>
                    <div className="safety-info">
                        <p className="info">
                            Safety is everyone’s responsibility and clear communication is key. Potential hazards on any project are discussed daily, and we encourage anyone to intervene if they see an unsafe act. The safety of our employees, the public and the environment is held in the highest regard, and we do everything we reasonably can to protect all three.
                        </p>
                    </div>
                </Stack>
            </Container>
            <Box className={"safety-hats"} sx={{ position: "relative" }}>
                <div className={"shader"}></div>
                <img alt="" className={"safety-img"} src={Safetyhats}></img>
            </Box>
        </Box>
    )

}